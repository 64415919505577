import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import { getCookie } from "../utils/etc";
import { ADM_KEY, ADM_VALUE } from "../utils/constants";

export default class UserStore {
  user: IUser | null = null;
  isAuth = Boolean(getCookie("access_token"));
  isAdmin = Boolean(getCookie(ADM_KEY) === ADM_VALUE);

  constructor() {
    makeAutoObservable(this);
  }

  setAuth = (bool: boolean) => {
    this.isAuth = bool;
  };

  setUser = (user: IUser | null) => {
    if (!user) return;

    this.user = user;
  };
}
