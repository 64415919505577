import { TableCell as TableCellMUI, TableCellProps } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useId, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import GateLink from "../GateLink";
import * as React from "react";

interface Props extends TableCellProps {
  name: string;
  linked: boolean;
  maxWidth?: number;
  minWidth?: number;
  forceTooltip?: boolean;
}

const TableCell = ({
  children,
  id,
  minWidth,
  maxWidth,
  sx,
  forceTooltip,
  name,
  linked,
  ...props
}: Props) => {
  const [textTitle, setTextTitle] = useState<string | undefined>(undefined);
  const textElement = useRef<any>(null);

  useEffect(() => {
    const textOverSize =
      textElement?.current?.scrollWidth > textElement?.current?.clientWidth;
    const text = textOverSize ? name : undefined;
    setTextTitle(text);
  }, []);

  return (
    <TableCellMUI
      sx={{
        // overflow: "hidden",
        // whiteSpace: "nowrap",
        // textOverflow: "ellipsis",
        ...sx,
      }}
      id={id}
      align="left"
      padding="normal"
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <GateLink linked={linked} />
        <Tooltip
          enterDelay={600}
          enterNextDelay={600}
          title={<Box sx={{ fontSize: "0.8rem", p: 0.5 }}>{name}</Box>}
          disableHoverListener={!textTitle}
          arrow
        >
          <Box
            ref={textElement}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Box>
        </Tooltip>
      </Box>
    </TableCellMUI>
  );
};

export default TableCell;
