import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { ChangeEvent, FC, Fragment } from "react";

type SearchComponentProps = {
  value: string;
  onChange: any;
  label: string;
  apply: any;
};

const SearchComponent: FC<SearchComponentProps> = ({
  value,
  label,
  onChange,
  apply,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const clearSearch = () => {
    if (!value) return;
    onChange("");
    apply("");
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const applySearch = () => {
    // if (!value) return;
    apply(value);
  };

  return (
    <Paper elevation={0} sx={{ width: "100%" }}>
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel size="small" htmlFor="search">
          {label}
        </InputLabel>
        <OutlinedInput
          value={value}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              applySearch();
            }
          }}
          id="outlined-adornment-password"
          type="text"
          size="small"
          fullWidth
          endAdornment={
            <Fragment>
              <InputAdornment position="end">
                <Box sx={{ width: 40 }}>
                  {value && (
                    <>
                      <IconButton
                        sx={{
                          width: 36,
                          height: 36,
                        }}
                        aria-label="toggle password visibility"
                        onClick={clearSearch}
                        onMouseDown={handleMouseDown}
                        edge="end"
                      >
                        <ClearIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    </>
                  )}
                </Box>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={applySearch}
                  onMouseDown={handleMouseDown}
                  edge="end"
                >
                  <SearchIcon color="secondary" />
                </IconButton>
              </InputAdornment>
            </Fragment>
          }
          label={label}
        />
      </FormControl>
    </Paper>
  );
};

export default SearchComponent;
