import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { StoreContext } from "../App";
import Header from "../components/Header";
import { MARKET } from "../routes/constants";
import { setCookie } from "../utils/etc";
import { useWS } from "../utils/websocket";
import { ReCAPTCHA_SITE_KEY } from "../utils/constants";

const Register = () => {
  const { user, captcha } = useContext(StoreContext);
  const { send } = useWS();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      full_name: "",
      password: "",
      recaptcha: "",
      // captcha: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim("Not a valid email address.")
        .strict(true)
        .email("Not a valid email address.")
        .max(255, "Email must be less than 256 characters")
        .required("Email is required"),
      full_name: Yup.string()
        .max(255, "Full name must be less than 256 characters")
        .required("Full name is required")
        .trim("Not a valid Full name.")
        .strict(true),
      password: Yup.string()
        .min(8, "Length must be more than 7 symbols.")
        .max(255, "Password must be less than 256 characters")
        .required("Password is required")
        .matches(/^[a-zA-Z0-9=+!@#$%^&*()]*$/, {
          excludeEmptyString: false,
          message: "Password is not valid",
        }),
      recaptcha: Yup.string().required("Confirm recaptcha"),
    }),
    onSubmit: async () => {
      let userData = {
        email: formik.values.email,
        password: formik.values.password,
        full_name: formik.values.full_name,
        g_recaptcha_response: formik.values.recaptcha,
      };

      let [registration, errors] = await send(
        "users",
        "registration",
        userData,
        false
      );

      if (registration) {
        user.setUser({
          full_name: registration.full_name,
          created_at: registration.created_at,
          email: registration.email,
          id: registration.id,
          balance: registration.balance,
          role: registration.role,
        });
        setCookie("access_token", registration.access_token);

        user.setAuth(true);
        navigate(`/${MARKET}`);
      } else {
        let textError = Object.keys(errors)
          .map((key) => errors[key])
          .join(" ");
        formik.setErrors({ email: textError });
      }
    },
  });

  const recaptchaError = formik.errors.recaptcha;

  return (
    <>
      <Header loginPage="register" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100vh",
        }}
      >
        <Container sx={{ width: 420 }}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ my: 3 }}>
                <Typography color="textPrimary" variant="h4">
                  Create a new account
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Use your email to create a new account
                </Typography>
              </Box>
              <TextField
                style={{
                  minHeight: 90,
                  margin: 0,
                }}
                error={
                  formik.touched.full_name && Boolean(formik.errors.full_name)
                }
                fullWidth
                helperText={formik.touched.full_name && formik.errors.full_name}
                label="Full Name"
                margin="normal"
                name="full_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.full_name}
                variant="outlined"
              />
              <TextField
                style={{
                  minHeight: 90,
                  margin: 0,
                }}
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="string"
                value={formik.values.email}
                variant="outlined"
              />
              <TextField
                style={{
                  minHeight: 90,
                  margin: 0,
                }}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />
              {/*<Box*/}
              {/*  sx={{*/}
              {/*    alignItems: 'center',*/}
              {/*    display: 'flex',*/}
              {/*    ml: -1*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Checkbox*/}
              {/*    checked={formik.values.policy}*/}
              {/*    name="policy"*/}
              {/*    onChange={formik.handleChange}*/}
              {/*  />*/}
              {/*</Box>*/}
              {/*{Boolean(formik.touched.policy && formik.errors.policy) && (*/}
              {/*  <FormHelperText error>*/}
              {/*    {formik.errors.policy}*/}
              {/*  </FormHelperText>*/}
              {/*)}*/}
              <Box
                sx={{
                  py: 2,
                  mx: "auto",
                  height: 130,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <ReCAPTCHA
                  sitekey={captcha}
                  onChange={(value) => formik.setFieldValue("recaptcha", value)}
                />
                {recaptchaError && formik.touched.recaptcha && (
                  <Typography
                    color="error"
                    fontSize={12}
                    sx={{ mx: 2, alignSelf: "self-start" }}
                  >
                    {recaptchaError}
                  </Typography>
                )}
              </Box>
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign Up Now
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Register;
