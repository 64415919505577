import { Box } from "@mui/system";
import { useState } from "react";
import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";

interface IProps {
  open: boolean;
  confirm: any;
  handleClose: () => void;
  title: string;
  text: string;
}

const ConfirmModal = ({ open, confirm, handleClose, title, text }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const confirmOnExit = async () => {
    setLoading(true);
    let res = await confirm();
    setLoading(false);
    if (res) {
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={title}
      confirm={confirmOnExit}
    >
      <Box
        style={{
          minHeight: 150,
          width: 360,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            margin: "auto",
          }}
        >
          {text}
        </Box>
        <ModalFooter
          loading={loading}
          confirmAction={confirmOnExit}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
