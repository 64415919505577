import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Modal from "../../components/Modal";
import { Box } from "@mui/system";
import {
  Button,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { StoreContext } from "../../App";
import { useWS } from "../../utils/websocket";
import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import BuyChannelsForm from "../../components/BuyChannelsForm";
import LinkGateForm from "../../components/LinkGateForm";
import { isEqual } from "lodash";
import { checkChannelsInCart } from "../../utils/etc";

const STEPS = ["Select channels", "Link channels"];

const BuyChannelsModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { cart, user } = useContext(StoreContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { send } = useWS();

  const createVirtualGate = useCallback(async (data: any) => {
    return await send(
      "virtual_gates",
      "create_with_goaf",
      {
        ...data,
      },
      "Virtual Gate successfully added"
    );
  }, []);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [cartData, setCartData] = useState(null);
  const [linkData, setLinkData] = useState(null);

  const validateChannels = async () => {
    if (!cart.cart.length) return;

    const [res] = await send("finance", "total", {
      channels: cart.cart.map((i) => i.id),
      rent_interval: 1,
    });

    if (res && !checkChannelsInCart(res.channels, cart.cart)) {
      cart.updateCartItems(res.channels);
      cart.changeState();
    }
  };

  useEffect(() => {
    validateChannels();
  }, []);

  const totalSteps = () => {
    return STEPS.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          STEPS.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const finish = async (data: any) => {
    //
    setIsLoading(true);

    let [result] = await createVirtualGate({
      // @ts-ignore
      ...cartData,
      ...data,
    });
    setIsLoading(false);

    if (result) {
      cart.cleanCart();
      cart.changeState();
      handleClose();
    }
  };

  const stepsContent: { [key: string]: any } = {
    "0": (
      <BuyChannelsForm
        initialData={cartData}
        getFormData={setCartData}
        nextStep={() => {
          handleNext();
        }}
        handleClose={handleClose}
      />
    ),
    "1": (
      <LinkGateForm
        getFormData={setLinkData}
        prevStep={handleBack}
        finish={finish}
        isLoading={isLoading}
      />
    ),
  };
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"Cart"}
      confirm={() => {}}
    >
      <Box
        sx={{
          width: 500,
        }}
      >
        <Stepper activeStep={activeStep}>
          {STEPS.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            paddingTop: 4,
            height: "100%",
          }}
        >
          {stepsContent[activeStep]}
        </Box>
      </Box>
    </Modal>
  );
};

export default observer(BuyChannelsModal);
