import { isEqual } from "lodash";
import { makeAutoObservable } from "mobx";
import { Filter } from "../containers/Tables/FilterList";

export default class MarketStore {
  data: any[] = [];
  filters: any[] = [];
  selected: number[] = [];
  touchedData: any[] = [];
  loadingFilters: boolean = false;
  marketStateLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data: any) => {
    if (!data) return;

    this.touchedData = [...this.regions];
    this.data = data;
  };

  setFilters = (filters: any) => {
    if (!filters) return;

    this.filters = filters;
    this.loadingFilters = false;
  };

  setSelectedChannels = (arrayOfIds: number[]) => {
    this.selected = [...arrayOfIds];
  };

  applyFilter = (name: string, checked: boolean) => {
    let filtersCopy = JSON.parse(JSON.stringify(this.filters));
    let checkedFilter = filtersCopy.find(
      (filter: Filter) => filter.name === name
    );
    checkedFilter.checked = checked;
    this.filters = filtersCopy;
  };

  setLoadingFilters = () => {
    this.loadingFilters = true;
  };

  get regions() {
    return (this.filters || [])
      .filter((filter) => filter.checked)
      .map((filter) => filter.id);
  }

  setMarketLoading = (state: boolean = true) => {
    this.marketStateLoading = state;
  };

  get touched() {
    return !isEqual(this.touchedData, this.regions);
  }

  get filteredData() {
    let selectedFilters = (this.filters || [])
      .filter((filter) => filter.checked)
      .map((filter) => filter.id);

    if (!selectedFilters.length) return this.data;

    return (this.data || []).filter((row) =>
      selectedFilters.includes(row.region_id)
    );
  }
}
