import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

interface CartItemProps {
  price: number;
  region: string;
  rating?: number;
  remove: () => void;
}

const CartItem = ({ price, region, remove, rating }: CartItemProps) => {
  const [style, setStyle] = useState({ display: "none" });

  return (
    <Box
      sx={{
        display: "flex",
        width: "70%",
        minHeight: 40,
        mx: "auto",
        flex: 1,
        // borderBottom: '1px solid rgba(0,0,0,0.05)'
      }}
      onMouseEnter={(e) => {
        setStyle({ display: "block" });
      }}
      onMouseLeave={(e) => {
        setStyle({ display: "none" });
      }}
    >
      <Box
        sx={{
          p: 1,
          flexGrow: 1,
          width: "50%",
        }}
      >
        <Typography align="left">{region}</Typography>
      </Box>
      <Box
        sx={{
          p: 1,
          flexGrow: 1,
          width: "50%",
        }}
      >
        <Typography align="center">${price}</Typography>
      </Box>
      <Box
        sx={{
          width: 0,
          height: 0,
        }}
      >
        <IconButton
          color={"primary"}
          sx={[
            {
              // color: "#121828",
              width: 24,
              height: 24,
              position: "relative",
              left: -24,
              top: 2,
            },
          ]}
          aria-label="Cart"
          size="small"
          onClick={remove}
          style={style}
        >
          <CloseIcon
            sx={{
              position: "relative",
              top: -3,
              fontSize: 12,
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CartItem;
