import { TableCell as TableCellMUI, TableCellProps } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useId, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import GateLink from "../GateLink";
import * as React from "react";

interface Props extends TableCellProps {
  maxWidth?: number;
  minWidth?: number;
}

const TableCell = ({
  children,
  id,
  minWidth,
  maxWidth,
  sx,
  ...props
}: Props) => {
  const [textTitle, setTextTitle] = useState<string | undefined>(undefined);
  const textElement = useRef<any>(null);

  useEffect(() => {
    const textOverSize =
      textElement?.current?.scrollWidth > textElement?.current?.clientWidth;
    const text =
      typeof children === "string" && textOverSize ? children : undefined;
    setTextTitle(text);
  }, [children]);

  return (
    <TableCellMUI
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        ...sx,
      }}
      id={id}
      align="center"
      padding="normal"
      {...props}
    >
      <Tooltip
        enterDelay={600}
        enterNextDelay={600}
        title={<Box sx={{ fontSize: "0.8rem", p: 0.5 }}>{textTitle}</Box>}
        disableHoverListener={!textTitle}
        arrow
        placement="top"
      >
        <Box id={useId()}>
          <Box
            ref={textElement}
            component="span"
            sx={{
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {children}
          </Box>
        </Box>
      </Tooltip>
    </TableCellMUI>
  );
};

export default TableCell;
