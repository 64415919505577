import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

type MenuItems = {
  method: () => void;
  name: string | JSX.Element;
  icon?: React.ElementType;
  useDivider?: boolean;
  hide?: boolean;
  disabled?: boolean;
};

interface IProps {
  children: any;
  items: MenuItems[];
  callback?: (event: React.MouseEvent) => void;
}

export default function ContextMenu({ children, items, callback }: IProps) {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    callback && callback(event);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  // const childrenRender = React.Children.map(props.children, child => {
  //   if (React.isValidElement(child)) {
  //     return React.cloneElement(child, { onContextMenu: handleContextMenu });
  //   }
  //   return child;
  // });
  const childrenRender = React.cloneElement(children, {
    onContextMenu: handleContextMenu,
  });

  return (
    <>
      {childrenRender}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        onClick={handleClose}
        anchorReference="anchorPosition"
        transitionDuration={0}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <Box sx={{ minWidth: 150 }}>
          {items
            .filter((i) => !i.hide)
            .map(({ method, icon, name, useDivider, disabled }, index) => {
              const IconComponent = icon as React.ElementType;
              return (
                <React.Fragment key={index}>
                  <MenuItem key={index} onClick={method} disabled={disabled}>
                    {icon && (
                      <IconComponent
                        color="primary"
                        sx={{ marginLeft: 1, marginRight: 2, fontSize: 13 }}
                      />
                    )}
                    <Typography sx={{ marginRight: 1, fontSize: 13 }}>
                      {name}
                    </Typography>
                  </MenuItem>
                  {useDivider && <Divider />}
                </React.Fragment>
              );
            })}
        </Box>
      </Menu>
    </>
  );
}
