import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import * as Yup from "yup";
import { StoreContext } from "../../App";

import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";
import { getTouchedData } from "../../utils/etc";
import { useWS } from "../../utils/websocket";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refresh: () => void;
}

const VALUES = {
  name: "",
};

const VirtualGatesModal = ({ open, handleClose, refresh }: IProps) => {
  const { updateAPI } = useWS();

  const { virtualDevices } = useContext(StoreContext);
  const { selectedVirtualGate } = virtualDevices;

  const initialValues = { ...VALUES, name: selectedVirtualGate.name };

  const updateVirtualGate = useCallback(async (id: number, data: any) => {
    return await updateAPI(
      "virtual_gates",
      {
        id,
        ...data,
      },
      "Virtual Gate successfully changed"
    );
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, "Name must be less than 256 characters")
        .required("Name is required")
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Name is not valid",
        }),
    }),
    onSubmit: async () => {
      let result;
      let touchedData = getTouchedData(selectedVirtualGate, formik.values);

      if (Object.keys(touchedData).length) {
        [result] = await updateVirtualGate(selectedVirtualGate.id, touchedData);
      }
      if (result) {
        refresh();
      }
      handleClose();
    },
  });

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Edit Virtual Gate"
      confirm={formik.submitForm}
    >
      <Box
        style={{
          width: 320,
          minHeight: 300,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            style={{
              minHeight: 70,
              margin: 0,
            }}
            size="small"
            error={Boolean(formik.touched.name && formik.errors.name)}
            fullWidth
            // @ts-ignore
            helperText={formik.touched.name && formik.errors.name}
            label="Name"
            margin="normal"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="name"
            value={formik.values.name}
            variant="outlined"
          />
        </form>
        <ModalFooter
          loading={formik.isSubmitting}
          confirmAction={formik.submitForm}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default observer(VirtualGatesModal);
