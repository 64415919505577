import { Grid } from "@mui/material";

const TableCard = (props: any) => {
  return (
    <Grid container sx={{ padding: 2, height: "100%" }}>
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default TableCard;
