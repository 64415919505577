import { makeAutoObservable } from "mobx";

export default class DevicesStore {
  gates: any[] = [];
  channels: any[] = [];
  selected: number[] = [];
  selectedChannelsData: number[] = [];
  gatesStateLoading: boolean = false;
  channelsStateLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setGates = (data: any) => {
    if (!data) return;

    this.gates = data;
  };

  setChannels = (data: any) => {
    if (!data) return;

    this.channels = data;
  };

  setSelectedDevices = (arrayOfIds: number[]) => {
    this.selectedChannelsData = [];
    this.selected = [...arrayOfIds];
  };

  setSelectedChannels = (arrayOfIds: number[]) => {
    this.selectedChannelsData = [...arrayOfIds];
  };

  setGatesLoading = (state: boolean = true) => {
    this.gatesStateLoading = state;
  };

  setChanelsLoading = (state: boolean = true) => {
    this.channelsStateLoading = state;
  };

  get selectedGate() {
    return (this.gates || []).filter((row) =>
      this.selected.includes(row.id)
    )[0];
  }

  get selectedChannels() {
    return (this.channels || []).filter((row) =>
      this.selected.includes(row.gate_id)
    );
  }

  get selectedChannelName() {
    return (
      (this.gates || []).find((row) => this.selected.includes(row.id))?.name ||
      ""
    );
  }

  get selectedTableChannels() {
    return (this.channels || []).filter((row) =>
      this.selectedChannelsData.includes(row.id)
    );
  }
}
