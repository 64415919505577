import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect } from "react";

import { Link, useMatch, useNavigate } from "react-router-dom";

function TTabs(props: any) {
  const navigate = useNavigate();
  const routeMatchRealStatistic = useMatch(`${props.path}/real`);
  const routeMatchRentedStatistic = useMatch(`${props.path}/rented`);

  const tabValue = (routeMatchRealStatistic || routeMatchRentedStatistic)
    ?.pathname;

  useEffect(() => {
    if (!tabValue) {
      navigate(`${props.path}/real`, { replace: true });
    }
  }, []);

  return (
    <Tabs value={tabValue || `${props.path}/real`}>
      <Tab
        label="My devices"
        value={`${props.path}/real`}
        to={`${props.path}/real`}
        component={Link}
        onClick={props.onChange}
      />
      <Tab
        label="Rented devices"
        value={`${props.path}/rented`}
        to={`${props.path}/rented`}
        component={Link}
        onClick={props.onChange}
      />
    </Tabs>
  );
}

export default function StatisticNav(props: any) {
  return (
    <Box sx={{ width: "100%", paddingLeft: 1 }}>
      <TTabs {...props} />
    </Box>
  );
}
