import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { StoreContext } from "../App";
import Header from "../components/Header";
import { MARKET } from "../routes/constants";
import { setCookie } from "../utils/etc";
import { useWS } from "../utils/websocket";
import { ADM_KEY, ADM_VALUE, ReCAPTCHA_SITE_KEY } from "../utils/constants";

const Login = () => {
  const { user, captcha } = useContext(StoreContext);
  const { send } = useWS();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || `/${MARKET}`;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptcha: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .max(255, "Email must be less than 256 characters")
        .trim("Not a valid email address.")
        .strict(true)
        .required("Email is required")
        .email("Not a valid email address."),
      password: Yup.string()
        .max(255, "Password must be less than 256 characters")
        .required("Password is required")
        .matches(/^[a-zA-Z0-9=+!@#$%^&*()]*$/, {
          excludeEmptyString: false,
          message: "Password is not valid",
        }),
      recaptcha: Yup.string().required("Confirm captcha"),
    }),
    onSubmit: async (values, formikHelpers) => {
      let userData = {
        login: formik.values.email,
        password: formik.values.password,
        g_recaptcha_response: formik.values.recaptcha,
      };

      let [login, errors] = await send("auth", "login", userData, false);

      if (login) {
        setCookie("access_token", login.refresh_token);
        let [userInfo] = await send("users", "user_info", {});

        //move it to suspense component...
        if (userInfo?.role === "admin") {
          setCookie(ADM_KEY, ADM_VALUE);
        }
        user.setUser({ ...userInfo });
        user.setAuth(true);
        navigate(from);
      } else {
        let textError = Object.keys(errors)
          .map((key) => errors[key])
          .join(", ");
        formik.setErrors({ password: textError, email: " " });
      }
    },
  });

  const recaptchaError = formik.errors.recaptcha;

  return (
    <>
      <Header loginPage="login" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100vh",
        }}
      >
        <Container sx={{ width: 420 }}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ my: 3 }}>
                <Typography color="textPrimary" variant="h4">
                  Sign in
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Use your email to sign in
                </Typography>
              </Box>
              <TextField
                style={{
                  minHeight: 86,
                  margin: 0,
                }}
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="string"
                value={formik.values.email}
                variant="outlined"
              />
              <TextField
                style={{
                  minHeight: 90,
                  margin: 0,
                }}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />
              <Box
                sx={{
                  py: 2,
                  mx: "auto",
                  height: 130,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <ReCAPTCHA
                  sitekey={captcha}
                  onChange={(value) => formik.setFieldValue("recaptcha", value)}
                />
                {formik.touched.recaptcha && recaptchaError && (
                  <Typography
                    color="error"
                    fontSize={12}
                    sx={{ mx: 2, alignSelf: "self-start" }}
                  >
                    {recaptchaError}
                  </Typography>
                )}
              </Box>
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign In Now
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Login;
