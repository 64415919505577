import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import {
  getComparator,
  Order,
  positionRender,
  stableSort,
} from "../../utils/etc";
import Loader from "../../components/Loader";
import { useRef } from "react";

interface Data {
  id: number;
  position: number;
  active: boolean;
  price: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "position",
    numeric: false,
    disablePadding: true,
    label: "Position",
    width: 150,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
    width: 150,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
    width: 150,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Select all",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: Data[];
  channelName: string;
  loading: boolean;
}

export default function DeviceTable({ data, channelName, loading }: IProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("position");
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onContextMenuTrigger = (event: React.MouseEvent, id: number) => {
    if (!selected.includes(id)) {
      setSelected([id]);
    }
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: "50%",
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: "50%" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {stableSort(data, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const onClickMethod = (event: React.MouseEvent) => {
                    return setSelected([row.id]);
                  };
                  const onCheckBoxClick = (event: React.MouseEvent) => {
                    event.stopPropagation();
                    return handleClick(event, row.id);
                  };

                  return (
                    // <ContextMenu
                    //   key={row.id}
                    //   items={[
                    //     {
                    //       name: "Edit",
                    //       method: () => {},
                    //       icon: SettingsIcon,
                    //     },
                    //   ]}
                    //   callback={(event) => onContextMenuTrigger(event, row.id)}
                    // >
                    <TableRow
                      hover
                      onClick={onClickMethod}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={onCheckBoxClick}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 150,
                          minWidth: 150,
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {positionRender(channelName, row.position)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          maxWidth: 150,
                          minWidth: 150,
                        }}
                      >
                        <CircleIcon
                          sx={{
                            color: row.active
                              ? "rgba(113, 255, 122, 0.8)"
                              : "rgba(255, 113, 113, 0.8)",
                            fontSize: 12,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 150,
                          minWidth: 150,
                        }}
                        align="center"
                      >
                        {row.price}
                      </TableCell>
                    </TableRow>
                    // </ContextMenu>
                  );
                }
              )}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * 15,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
