import { Box, Divider, Grid } from "@mui/material";
import { isNull, omitBy } from "lodash";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import StatisticNav from "../components/primitives/StatisticNav";
import TableCard from "../components/primitives/TableCard";
import StatiscticFilters from "../containers/Tables/StatiscticFilters";
import Table from "../containers/Tables/StatisticTable";
import { useWS } from "../utils/websocket";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

const Statistic = (props: any) => {
  const { statistics } = useContext(StoreContext);
  const match = useMatch("/statistic/:type");
  const statisticType = match?.params.type || "real";

  const {
    statistic,
    setStatistic,
    date,
    setStartDate,
    setEndDate,
    touched,
    loading,
    setLoadingState,
    clearDate,
  } = statistics;
  const { start_date, end_date } = date;

  const { send } = useWS();

  const getStatistic = async () => {
    setLoadingState(true);

    let data = {
      end_date,
      start_date,
      limit: 999999,
      offset: 0,
    };

    let module: { [key: string]: string } = {
      real: "channels",
      rented: "virtual_channels",
    };

    let [statistic] = await send(module[statisticType], "statistics", {
      ...omitBy(data, isNull),
    });

    setLoadingState(false);
    setStatistic(statistic, statisticType);
  };

  useEffect(() => {
    async function fetch() {
      await getStatistic();
    }

    fetch();
  }, [statisticType]);

  return (
    <Main
      disableNav
      helper={<StatisticNav path="/statistic" onChange={clearDate} />}
      path="/statistic"
    >
      <Box
        component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ flexGrow: 1, width: 230 }}>
            <StatiscticFilters
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={getStatistic}
              touched={touched}
              date={date}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sx={{ flexGrow: 2, height: "100%" }}>
            <TableCard>
              <Table
                data={statistic.get(statisticType) || []}
                loading={loading}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default observer(Statistic);
