import { Box, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import TableCard from "../components/primitives/TableCard";
import SearchComponent from "../components/SearchComponent";
import Table from "../containers/Tables/AdminDevicesTable";
import List from "../containers/Tables/AdminUserList";
import { useWS } from "../utils/websocket";

const AdminDevices = () => {
  const [searchKey, setSearchKey] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const { adminUsers } = useContext(StoreContext);

  const {
    users,
    devices,
    getDevicesData,
    setUsers,
    setDevices,
    setSelectedUsers,
    selected,
    usersStateLoading,
    setUsersStateLoading,
    devicesStateLoading,
    setDevicesStateLoading,
  } = adminUsers;

  const { send, deleteAPI, getAPI } = useWS();

  const getUsers = useCallback(
    async (searchKey: string) => {
      setUsersStateLoading(true);

      let param = searchKey.length ? { name_or_email_search: searchKey } : {};
      let [users, error] = await send("users", "list", {
        limit: 999999,
        offset: 0,
        ...param,
      });
      setUsers(users);

      setUsersStateLoading(false);
    },
    [searchKey]
  );

  const getDevicesRequest = useCallback(async () => {
    let userID = selected.length && selected[0];

    if (!userID) return;
    setDevicesStateLoading(true);

    let [devices, error] = await send("virtual_channels", "list_link", {
      limit: 999999,
      offset: 0,
      user_id: userID,
    });
    setDevices(userID, devices);

    setDevicesStateLoading(false);
  }, [selected]);

  const refresh = useCallback(
    async (search: string = "") => {
      await getUsers(search || searchKey);
    },
    [searchKey]
  );

  const refreshWithClearing = useCallback(
    async (searchKey: string = "") => {
      setSelectedUsers([]);
      await getUsers(searchKey);
    },
    [searchKey]
  );

  useEffect(() => {
    async function fetch() {
      await refresh();
    }

    fetch();

    return () => {
      setSelectedUsers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDevicesRequest();
  }, [selected]);

  const searchComponent = useMemo(() => {
    return (
      <SearchComponent
        value={searchKey}
        onChange={setSearchKey}
        label="Search by name or email"
        apply={refreshWithClearing}
      />
    );
  }, [searchKey]);

  return (
    <Main disableNav helper={searchComponent}>
      <Box
        // component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ flexGrow: 1, width: 420 }}>
            <TableCard>
              <List
                loading={usersStateLoading}
                refresh={refresh}
                data={users}
                selectData={setSelectedUsers}
                selected={selected}
              />
            </TableCard>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sx={{ flexGrow: 7 }}>
            <TableCard>
              <Table
                loading={devicesStateLoading}
                data={getDevicesData}
                select={setSelectedRow}
                selected={selectedRow}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default observer(AdminDevices);
