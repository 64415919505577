import CloseRounded from "@mui/icons-material/CloseRounded";
import { Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { ReactNode, useCallback, useEffect } from "react";
import { actionByEnterPress } from "../utils/etc";

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  // bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: "100svh",
  overflow: "auto",
  borderRadius: "8px",
};

export default function BasicModal({
  open,
  handleClose,
  title,
  children,
  styles,
  confirm,
  color = "secondary.500",
}: {
  open: boolean;
  handleClose: () => void;
  title: string | ReactNode;
  children: ReactNode;
  confirm: () => void;
  styles?: any;
  color?: string;
}) {
  const confirmByEnter = useCallback((e: KeyboardEvent) => {
    actionByEnterPress(e, confirm);
  }, []);

  useEffect(() => {
    document.addEventListener("keypress", confirmByEnter);

    return () => {
      document.removeEventListener("keypress", confirmByEnter);
    };
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      disableEscapeKeyDown
    >
      <Box sx={{ ...style, ...styles }}>
        <Card
          sx={{
            boxSizing: "border-box",
            maxWidth: "100%",
            maxHeight: "100%",
            overflowY: "auto",
            background: "#fff",
          }}
        >
          <CardHeader
            action={
              <IconButton
                aria-label="close"
                onClick={handleClose}
                color={"primary"}
              >
                <CloseRounded />
              </IconButton>
            }
            title={title}
            sx={{
              backgroundColor: color,
              color: "secondary.contrastText",
            }}
          />
          <Divider />
          <CardContent>{children}</CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
