import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { StoreContext } from "../App";

import { LOGIN, MARKET } from "../routes/constants";

interface AuthContextType {
  user: any;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  let value = { user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(StoreContext).user;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isAuth) {
    return <Navigate to={`/${LOGIN}`} state={{ from: location }} />;
  }

  return children;
}

export function RequireAdmin({ children }: { children: JSX.Element }) {
  let user = useAuth();
  if (!user.isAdmin) {
    return <Navigate to={`/${MARKET}`} />;
  }

  return children;
}

export function RequireAuthLink({ children }: { children: JSX.Element }) {
  let auth = useAuth();

  if (!auth.isAuth) {
    return null;
  }

  return children;
}

export function RequireAdminLink({ children }: { children: JSX.Element }) {
  let auth = useAuth();

  if (!auth.isAdmin) {
    return null;
  }

  return children;
}
