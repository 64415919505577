import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { createContext, FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Main } from "./routes";
import AdminUserStore from "./store/AdminUserStore";
import CartStore from "./store/CartStore";
import DevicesStore from "./store/DevicesStore";
import MarketStore from "./store/MarketStore";
import OptionsStore from "./store/OptionsStore";
import StatisticStore from "./store/StatisticStore";
import UserStore from "./store/UserStore";
import VirtualStore from "./store/VirtualStore";
import NotificationsStore from "./store/NotificationsStore";
import { theme } from "./theme";
import { AuthProvider } from "./utils/Auth";
import { WSProvider } from "./utils/websocket";

interface State {
  user: UserStore;
  market: MarketStore;
  cart: CartStore;
  devices: DevicesStore;
  virtualDevices: VirtualStore;
  options: OptionsStore;
  adminUsers: AdminUserStore;
  statistics: StatisticStore;
  notifications: NotificationsStore;
  captcha: string;
}

export const user = new UserStore();
export const market = new MarketStore();
export const cart = new CartStore();
export const devices = new DevicesStore();
export const virtualDevices = new VirtualStore();
export const options = new OptionsStore();
export const adminUsers = new AdminUserStore();
export const statistics = new StatisticStore();
export const notifications = new NotificationsStore();

export const StoreContext = createContext<State>({
  devices,
  user,
  market,
  cart,
  virtualDevices,
  options,
  adminUsers,
  statistics,
  notifications,
  captcha: "",
});

type IProps = {
  wsURL: string;
  captchaSiteKey: string;
};

const App: FC<IProps> = ({ wsURL, captchaSiteKey }) => {
  return (
    <StoreContext.Provider
      value={{
        devices,
        user,
        market,
        cart,
        virtualDevices,
        options,
        adminUsers,
        statistics,
        notifications,
        captcha: captchaSiteKey,
      }}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <WSProvider wsURL={wsURL}>
            <AuthProvider>
              <CssBaseline />
              <Router>
                <Main />
              </Router>
            </AuthProvider>
          </WSProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StoreContext.Provider>
  );
};

export default App;
