import { Link as RouterLink, useMatch } from "react-router-dom";
import { Box, Link as MLink } from "@mui/material";

export default function Link(props: any) {
  const match = useMatch(`${props.to}/*`);

  return (
    <MLink
      component={RouterLink}
      to={props.to}
      sx={{
        p: 3,
        backgroundColor: match && "secondary.light",
        height: "100%",
        textDecoration: "none",
        color: match && "primary.contrastText",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
      }}
    >
      <Box>{props.label}</Box>
    </MLink>
  );
}
