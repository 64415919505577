import { Box } from "@mui/system";
import { useState } from "react";
import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";
import { Button, Typography } from "@mui/material";

interface IProps {
  open: boolean;
  confirm: any;
  handleClose: () => void;
  terminateRent: any;
  inRent: boolean;
}

const RemoveGateModal = ({
  open,
  confirm,
  handleClose,
  terminateRent,
  inRent,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inRentState, setInRentState] = useState<boolean>(inRent);

  const confirmOnExit = async () => {
    setLoading(true);
    let res = await confirm();
    setLoading(false);
    if (res) {
      handleClose();
    }
  };

  const terminate = async () => {
    setLoading(true);
    let res = await terminateRent();
    setLoading(false);
    if (res) {
      setInRentState(false);
    }
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"Delete gate"}
      confirm={confirmOnExit}
    >
      <Box
        style={{
          minHeight: 150,
          width: 400,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            margin: "auto",
          }}
        >
          {inRentState ? (
            <Box>
              <Box>
                <Typography align="center">
                  Your gate is currently in rent.
                </Typography>
                <Typography align="center">
                  If you want to delete it, you need to terminate rent first.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                  marginBottom: 5,
                }}
              >
                <Button size="large" onClick={terminate} disabled={loading}>
                  Terminate
                </Button>
              </Box>
            </Box>
          ) : (
            "Confirm that you want to delete gate"
          )}
        </Box>
        <ModalFooter
          loading={loading}
          confirmAction={confirmOnExit}
          closeAction={handleClose}
          disabled={inRentState}
        />
      </Box>
    </Modal>
  );
};

export default RemoveGateModal;
