import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import * as Yup from "yup";
import { StoreContext } from "../../App";

import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";
import { getTouchedData } from "../../utils/etc";
import { useWS } from "../../utils/websocket";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refresh: () => void;
  // type: 'create' | 'edit'
}

const INITIAL_VALUES = {
  email: "",
};

const AdminChangeEmailModal = ({ open, handleClose, refresh }: IProps) => {
  const { adminUsers } = useContext(StoreContext);

  const { selectedUser } = adminUsers;

  const { send } = useWS();

  const initialValues = { ...INITIAL_VALUES };

  const updateEmail = useCallback(async (id: number, newEmail: string) => {
    return await send(
      "users",
      "change_email",
      {
        user_id: id,
        new_email: newEmail,
      },
      "Email successfully changed"
    );
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .max(255, "Email must be less than 256 characters")
        .trim("Not a valid email address.")
        .strict(true)
        .required("Email is required")
        .email("Not a valid email address."),
    }),
    onSubmit: async () => {
      let result;
      let touchedData = getTouchedData({ ...initialValues }, formik.values);

      if (Object.keys(touchedData).length) {
        // @ts-ignore
        [result] = await updateEmail(selectedUser.id, touchedData.email);
      }
      if (result) {
        refresh();
      }
      handleClose();
    },
  });

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"Change Email"}
      confirm={formik.submitForm}
    >
      <Box
        style={{
          width: 380,
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            style={{
              minHeight: 70,
              margin: 0,
            }}
            size="small"
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={
              Boolean(formik.touched.email && formik.errors.email) &&
              formik.errors.email
            }
            label="Email"
            margin="normal"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="string"
            value={formik.values.email}
            variant="outlined"
          />
        </form>
        <ModalFooter
          loading={formik.isSubmitting}
          confirmAction={formik.submitForm}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default observer(AdminChangeEmailModal);
