import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { ChangeEvent } from "react";

export type Filter = {
  id: number | string;
  name: string;
  checked?: boolean;
};

interface IProps {
  filters: Filter[];
  handleChange: (name: string, checked: boolean) => void;
  onApply: () => void;
  touched: boolean;
}

export default function FilterList({
  filters,
  handleChange,
  onApply,
  touched,
}: IProps) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.name, event.target.checked);
  };

  const renderFilters = () => {
    return (filters || []).map((filter) => (
      <FormControlLabel
        key={filter.id}
        control={
          <Checkbox
            checked={filter.checked ?? false}
            onChange={onChange}
            name={filter.name}
          />
        }
        label={filter.name}
      />
    ));
  };

  const renderContent = () => {
    // return loading ? (
    //   <Skeleton variant="rectangular" width={210} height={118} />
    // ) : (
    //   <FormControl sx={{mx: 3, my: 2}} component="fieldset" variant="standard">
    //     <FormLabel component="legend">Region</FormLabel>
    //     <FormGroup>{renderFilters()}</FormGroup>
    //   </FormControl>
    // )
    return (
      <FormControl
        sx={{ mx: 3, my: 2 }}
        component="fieldset"
        variant="standard"
      >
        <FormLabel sx={{ mb: 2 }} component="legend">
          Region
        </FormLabel>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            maxHeight: "calc(100vh - 350px)",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {renderFilters()}
        </FormGroup>
      </FormControl>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box sx={{ minHeight: 139 }}>{renderContent()}</Box>
      <Box sx={{ display: "flex", my: 3, justifyContent: "end" }}>
        {touched && (
          <Box>
            <Button
              sx={{
                width: 100,
                height: 32,
                mr: 4,
              }}
              color="secondary"
              fullWidth
              size="small"
              variant="outlined"
              onClick={onApply}
              disabled={!touched}
            >
              Apply
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
