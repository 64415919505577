import { useSnackbar } from "notistack";
import * as React from "react";
import WebSocketAsPromised from "websocket-as-promised";
import { CREATE, DELETE, GET, UPDATE } from "./constants";
import { deleteCookie, getCookie } from "./etc";

type ERROR_CODE = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
type ERROR_MESSAGE = string;

function error_handling(
  error_code: ERROR_CODE,
  error_message: ERROR_MESSAGE
): { error: string; notification: boolean } {
  let notification = false;
  let error = error_message;

  // if (error_code === -1) {
  //   notification = true;
  // }

  if (error_code === 0 && error_message.hasOwnProperty("wrong_credentials")) {
    // notification = true;
    deleteCookie("access_token");
    window.location.href = "/login";
  }

  if (error_code === 7) {
    notification = true;
  }

  return { error, notification };
}

interface WSContextType {
  send: (
    module: string,
    method: string,
    data: object | null,
    showNotification?: string | boolean
  ) => Promise<any>;
  logOut: () => Promise<any>;
  getAPI: (
    module: string,
    data?: object,
    showNotification?: string | boolean
  ) => Promise<any>;
  updateAPI: (
    module: string,
    data: object | null,
    showNotification?: string | boolean
  ) => Promise<any>;
  deleteAPI: (
    module: string,
    data: object | null,
    showNotification?: string | boolean
  ) => Promise<any>;
  createAPI: (
    module: string,
    data: object | null,
    showNotification?: string | boolean
  ) => Promise<any>;
  subscribe: (key: string, func: (data: any) => void) => void;
  unsubscribe: (key: string) => void;
}

let WSContext = React.createContext<WSContextType>(null!);

export function WSProvider({
  children,
  wsURL,
}: {
  children: React.ReactNode;
  wsURL: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  let request_id = 1;
  let keepAlive: string | null = null;

  let subscriptions = new Map<string, (data: any) => void>();

  const subscribe = (key: string, func: (data: any) => void) => {
    subscriptions.set(key, func);
  };

  const unsubscribe = (key: string) => {
    subscriptions.delete(key);
  };

  const wsp = new WebSocketAsPromised(wsURL, {
    packMessage: (data) => JSON.stringify(data),
    unpackMessage: (data) => {
      if (data === "pong") return data;
      return JSON.parse(data as string);
    },
    attachRequestId: (data, requestId) =>
      Object.assign({ request_id: requestId }, data),
    extractRequestId: (data) => data && data.request_id,
  });

  const sendMessage = async (
    module: string,
    method: string,
    data: object | null,
    showNotification: string | boolean = false
  ) => {
    let token = getCookie("access_token");
    return wsp
      .open()
      .then(() =>
        wsp.sendRequest(
          { module, method, data, token: token || "" },
          { requestId: request_id++ }
        )
      )
      .then((res) => {
        let result, error;

        if (res.error_message) {
          let error_message =
            res.error_message.message ?? JSON.stringify(res.error_message);

          let { error: resError, notification } = error_handling(
            res.error_code,
            error_message
          );
          if (resError && (showNotification || notification)) {
            let message = Object.keys(res.error_message).map((key) => {
              return res.error_message[key];
            });

            enqueueSnackbar(message.join(","), {
              variant: "error",
              preventDuplicate: true,
            });
          }
          error = res.error_message;
        }

        if (res.response?.success === false) {
          error = res.response.error;
        }

        if (res.success) {
          result = res.response.data || res.success;
          if (showNotification) {
            enqueueSnackbar(showNotification, {
              variant: "success",
              preventDuplicate: true,
            });
          }
        }

        return [result, error];
      })
      .catch((e) => {
        enqueueSnackbar(`Connection failed: ${e.message}`, {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  const logOut = async () => {
    let token = getCookie("access_token");
    return wsp
      .open()
      .then(() =>
        wsp.sendRequest(
          { module: "auth", method: "logout", data: {}, token: token || "" },
          { requestId: request_id++ }
        )
      )
      .then(() => {
        return wsp.close();
      })
      .catch((e) => {
        // enqueueSnackbar(`Connection failed: ${e.message}`, {
        //   variant: "error",
        //   preventDuplicate: true,
        // });
      });
  };

  const send = async (
    module: string,
    method: string,
    data: object | null,
    showNotification: string | boolean = false
  ) => {
    // if (!authed) {
    //   await sendMessage(module, method, data) //change to auth method
    //   console.log('auth sent')
    //   authed = true
    // }
    return sendMessage(module, method, data, showNotification);
  };

  const getAPI = async (
    module: string,
    data: object = {},
    showNotification: string | boolean = false
  ) => {
    let dataObject = { limit: 999999, offset: 0, ...data };
    return send(module, GET, dataObject, showNotification)
      .then((res) => res)
      .catch((e) => console.error("Send error"));
  };

  const updateAPI = async (
    module: string,
    data: object | null,
    showNotification: string | boolean = false
  ) => {
    return await send(module, UPDATE, data, showNotification);
  };

  const deleteAPI = async (
    module: string,
    data: object | null,
    showNotification: string | boolean = false
  ) => {
    return await send(module, DELETE, data, showNotification);
  };

  const createAPI = async (
    module: string,
    data: object | null,
    showNotification: string | boolean = false
  ) => {
    return await send(module, CREATE, data, showNotification);
  };

  let value = {
    send,
    logOut,
    getAPI,
    updateAPI,
    deleteAPI,
    createAPI,
    subscribe,
    unsubscribe,
  };

  let pingFunction = () => {
    if (!keepAlive) {
      setInterval(async function () {
        if (!wsp.isOpened) await wsp.open();

        wsp.send("ping");
      }, 45 * 1000);
    }
  };

  wsp.onOpen.addListener(() => pingFunction());
  wsp.onClose.addListener(() => wsp.removeAllListeners());

  return <WSContext.Provider value={value}>{children}</WSContext.Provider>;
}

export function useWS() {
  return React.useContext(WSContext);
}
