import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import { StoreContext } from "../App";
import { useWS } from "../utils/websocket";
import CartItem from "./CartItem";
import { LOGIN } from "../routes/constants";
import TextInput from "./primitives/TextInput";
import IntervalField from "./primitives/IntervalField";
import { checkChannelsInCart } from "../utils/etc";
import { useSnackbar } from "notistack";

const BuyChannelsForm = ({
  getFormData,
  nextStep,
  handleClose,
  initialData,
}: {
  getFormData: any;
  nextStep: any;
  handleClose: () => void;
  initialData?: any;
}) => {
  const [total, setTotal] = useState<number>(0);
  const { cart, user } = useContext(StoreContext);

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const { send } = useWS();

  const createVirtualGate = useCallback(async (data: any) => {
    return await send(
      "virtual_gates",
      "create",
      {
        ...data,
      },
      "Virtual Gate successfully changed"
    );
  }, []);

  const renderCartData = () => {
    return cart.cart.map((data, index) => {
      const removeCartItem = () => cart.removeFromCart(data.id);
      return (
        <Fragment key={index}>
          <CartItem
            price={data.price}
            region={data.region_name}
            remove={removeCartItem}
          />
        </Fragment>
      );
    });
  };

  const formik = useFormik({
    initialValues: {
      name: cart.modalSavedData?.name || initialData?.name || "",
      rent_interval:
        cart.modalSavedData?.rent_interval || initialData?.rent_interval || 1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, "Name must be less than 256 characters")
        .required("Name is required")
        .trim("Name is not valid.")
        .strict(true)
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Name is not valid",
        }),
      rent_interval: Yup.number()
        .positive("Rent interval should be greater than 0")
        .required("Rent interval is required"),
    }),
    onSubmit: async () => {
      let error = false;
      const { name, rent_interval } = formik.values;
      if (!user.isAuth) {
        navigate(`/${LOGIN}`, {
          state: {
            from: location,
          },
        });

        // @ts-ignore
        cart.setModalStateData({ name, rent_interval });
        handleClose();
        return;
      }

      let channels = cart.cart.map((i) => i.id);

      const [res] = await send("finance", "total", {
        channels: cart.cart.map((i) => i.id),
        rent_interval: formik.values.rent_interval,
      });

      if (res) {
        setTotal(res.total_price);
        if (!checkChannelsInCart(res.channels, cart.cart)) {
          cart.updateCartItems(res.channels);
          cart.changeState();
          error = true;
          enqueueSnackbar("Some channels are not available", {
            variant: "warning",
          });
        }
      }

      if (channels.length && !error) {
        nextStep();
        getFormData({ name, rent_interval, channels });
      }
    },
  });

  useEffect(() => {
    if (cart.modalSavedData) {
      formik.setFieldValue("name", cart.modalSavedData.name);
      formik.setFieldValue("rent_interval", cart.modalSavedData.rent_interval);
      cart.setModalStateData(null);
      // setSavedCart(null);
    }
  }, []);

  const getPrice = useCallback(async () => {
    const [res] = await send("finance", "total", {
      channels: cart.cart.map((i) => i.id),
      rent_interval: formik.values.rent_interval,
    });

    if (res) {
      setTotal(res.total_price);
      cart.updateCartItems(res.channels);
    }
  }, [formik.values.rent_interval, cart.cart.length]);

  useEffect(() => {
    getPrice();
  }, [formik.values.rent_interval, cart.cart.length]);

  const renderFooter = useMemo(() => {
    let toPay = total - (user.user?.balance ?? 0);
    let toPayValue =
      toPay > 0
        ? toPay.toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")
        : null;

    return (
      <Box sx={{ paddingLeft: 2, minHeight: 130 }}>
        <Box
          sx={{
            paddingLeft: "5rem",
            display: "flex",
            alignItems: "flex-start",
            lineHeight: "1.8rem",
          }}
        >
          <Box
            sx={{
              flex: "1 1 60px",
              textTransform: "uppercase",
            }}
          >
            Balance
          </Box>
          <Box
            sx={{
              flex: "1 1 60px",
              textTransform: "uppercase",
            }}
          >
            ${user.user?.balance}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            paddingLeft: "5rem",
            alignItems: "flex-start",
            lineHeight: "1.8rem",
          }}
        >
          <Box
            sx={{
              flex: "1 1 60px",
              textTransform: "uppercase",
            }}
          >
            Total
          </Box>
          <Box
            sx={{
              flex: "1 1 60px",
              textTransform: "uppercase",
            }}
          >
            ${total}
          </Box>
        </Box>
        {toPayValue && (
          <Box
            sx={{
              display: "flex",
              paddingLeft: "5rem",
              alignItems: "flex-start",
              lineHeight: "1.8rem",
            }}
          >
            <Box
              sx={{
                flex: "1 1 60px",
                textTransform: "uppercase",
              }}
            >
              To pay
            </Box>
            <Box
              sx={{
                flex: "1 1 60px",
                textTransform: "uppercase",
              }}
            >
              ${toPayValue}
            </Box>
          </Box>
        )}
        <Box sx={{ marginBottom: 4 }} />
      </Box>
    );
  }, [user, cart.cart, total]);

  return (
    <Box
      style={{
        minHeight: 300,
        height: "100%",
        // minWidth: 350,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {cart.cart.length ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <TextInput formik={formik} dataField="name" label="Name" />
            <Box sx={{ marginBottom: 1 }} />
            <IntervalField
              formik={formik}
              value={formik.values.rent_interval}
              label="Rental duration"
              dataField="rent_interval"
              onChange={(value) => formik.setFieldValue("rent_interval", value)}
            />
          </form>
          <Box
            sx={{
              width: "100%",
              maxHeight: 250,
              minHeight: 100,
              overflow: "auto",
              // p: 1,
              "&>*:nth-child(2n) .MuiBox-root": {
                backgroundColor: "rgba(213,213,213,0.12)",
              },
              marginBottom: 5,
              marginTop: 3,
            }}
          >
            {renderCartData()}
          </Box>
          <Divider sx={{ marginBottom: 3 }} />
          {renderFooter}
          <Button
            onClick={(e) => formik.handleSubmit()}
            sx={{ mr: 1 }}
            disabled={(user.user?.balance || 0) < total}
          >
            Next step
          </Button>
        </>
      ) : (
        <Box
          style={{
            minHeight: 300,
            height: "100%",
            // minWidth: 350,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box style={{ textAlign: "center", marginTop: 100 }}>
            <Typography variant="h6" color="text.secondary">
              EMPTY CART
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-evenly",
              marginTop: "auto",
            }}
          >
            <Box>
              <Button
                sx={{
                  py: 2,
                  alignSelf: "flex-end",
                  margin: "auto",
                  width: 140,
                }}
                color="success"
                fullWidth
                size="small"
                variant="outlined"
                onClick={handleClose}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default observer(BuyChannelsForm);
