import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import PasswordIcon from "@mui/icons-material/Password";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import ContextMenu from "../../components/ContextMenu";
import { getComparator, Order, stableSort } from "../../utils/etc";
import AdminChangeEmailModal from "../Modals/AdminChangeEmailModal";
import AdminChangePasswordModal from "../Modals/AdminChangePasswordModal";
import AdminDepositModal from "../Modals/AdminDepositModal";
import AdminWithdrawModal from "../Modals/AdminWithdrawModal";
import Loader from "../../components/Loader";
import { Grid } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { TABLE_PAGINATION } from "../../utils/constants";
import { useEffect, useRef } from "react";

type Data = {
  id: number;
  email: string;
  balance: number;
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    width: 160,
  },
  {
    id: "balance",
    numeric: false,
    disablePadding: false,
    label: "Balance",
    width: 100,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
            key={headCell.id}
            align="center"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: Data[];
  selectData: (arrayOfIds: number[]) => void;
  selected: number[];
  refresh: any;
  loading: boolean;
}

export default function AdminUserList({
  data = [],
  selectData,
  selected,
  refresh,
  loading,
}: IProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("email");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PAGINATION[0]);

  const [passwordModal, handlePasswordModal] = React.useState(false);
  const [emailModal, handleEmailModal] = React.useState(false);
  const [depositModal, handleDepositModal] = React.useState(false);
  const [withdrawModal, handleWithdrawModal] = React.useState(false);

  useEffect(() => {
    setPage(0);
  }, [data.length]);

  const openPasswordModal = () => {
    handlePasswordModal(true);
  };
  const closePasswordModal = () => {
    handlePasswordModal(false);
  };

  const openEmailModal = () => {
    handleEmailModal(true);
  };
  const closeEmailModal = () => {
    handleEmailModal(false);
  };

  const openDepositModal = () => {
    handleDepositModal(true);
  };
  const closeDepositModal = () => {
    handleDepositModal(false);
  };

  const openWithdrawModal = () => {
    handleWithdrawModal(true);
  };
  const closeWithdrawModal = () => {
    handleWithdrawModal(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      selectData(newSelecteds);
      return;
    }
    selectData([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    selectData([id]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: "50%",
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: "50%" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ContextMenu
                      key={row.id}
                      items={[
                        {
                          name: "Change email",
                          method: openEmailModal,
                          icon: AlternateEmailIcon,
                        },
                        {
                          name: "Change password",
                          method: openPasswordModal,
                          icon: PasswordIcon,
                        },
                        {
                          name: "Deposit",
                          method: openDepositModal,
                          icon: ControlPointIcon,
                        },
                        {
                          name: "Withdraw",
                          method: openWithdrawModal,
                          icon: RemoveCircleOutlineIcon,
                        },
                      ]}
                      callback={(event) => handleClick(event, row.id)}
                    >
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell
                          sx={{
                            maxWidth: 210,
                            minWidth: 210,
                          }}
                          id={labelId}
                          align="left"
                          padding="normal"
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 160,
                            minWidth: 160,
                          }}
                          align="center"
                          padding="normal"
                        >
                          {row.balance}
                        </TableCell>
                      </TableRow>
                    </ContextMenu>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          sx={{
            flex: 1,
            margin: "auto",
            alignItems: "center",
            justifyContent: "space-arround",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{
              position: "relative",
              left: 35,
            }}
          ></Grid>
          <Grid
            item
            sx={{
              marginLeft: "auto",
            }}
          >
            <TablePagination
              sx={{
                ".MuiButtonBase-root": {
                  color: "primary.main",
                },
                ".Mui-disabled": {
                  color: "primary.light",
                },
              }}
              rowsPerPageOptions={TABLE_PAGINATION}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
      {emailModal ? (
        <AdminChangeEmailModal
          open={emailModal}
          handleClose={closeEmailModal}
          refresh={refresh}
        />
      ) : null}
      {passwordModal ? (
        <AdminChangePasswordModal
          open={passwordModal}
          handleClose={closePasswordModal}
          refresh={refresh}
        />
      ) : null}
      {depositModal ? (
        <AdminDepositModal
          open={depositModal}
          handleClose={closeDepositModal}
          refresh={refresh}
        />
      ) : null}
      {withdrawModal ? (
        <AdminWithdrawModal
          open={withdrawModal}
          handleClose={closeWithdrawModal}
          refresh={refresh}
        />
      ) : null}
    </Box>
  );
}
