import { uniqBy } from "lodash";
import { makeAutoObservable } from "mobx";
import { getCookie, setCookie } from "../utils/etc";
import { useWS } from "../utils/websocket";

const CART_COOKIE = "cart";

export default class CartStore {
  getCartFromCookies = (): any[] | null => {
    let cartCookie = getCookie(CART_COOKIE);
    return cartCookie ? JSON.parse(cartCookie)?.cart : null;
  };

  cart: any[] = this.getCartFromCookies() || [];
  stateChanged: boolean = false;
  modalSavedData: { name: string; rent_interval: number } | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCartCookie = (data: any[]): void => {
    let date = new Date(Date.now() + 3600 * 1000 * 24);
    setCookie("cart", JSON.stringify({ cart: data }), { expires: date });
  };

  cleanCart = (): void => {
    this.cart = [];
    this.setCartCookie([]);
  };

  addToCart = (channel: any) => {
    this.cart = uniqBy(this.cart.concat(channel), "id");

    this.setCartCookie(this.cart);
  };

  removeFromCart = (id: number) => {
    this.cart = [...this.cart.filter((data) => data.id !== id)];

    this.setCartCookie(this.cart);
  };

  updateCartItems(data: any[]) {
    // if (!data.length) return;

    let dataIds = data.map((d) => d.id);

    this.cart = this.cart
      .filter((i) => dataIds.includes(i.id))
      .map((i) => {
        let changedItem = data.find((d) => d.id === i.id);
        let item = { ...i };
        item.price = changedItem?.price || item.price;
        return item;
      });

    this.setCartCookie(this.cart);
  }

  changeState = () => {
    this.stateChanged = !this.stateChanged;
  };

  setModalStateData = (
    data: { name: string; rent_interval: number } | null
  ) => {
    this.modalSavedData = data;
  };
}
