import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import * as Yup from "yup";
import { StoreContext } from "../../App";

import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";
import NumericInput from "../../components/primitives/NumericInput";
import { getTouchedData } from "../../utils/etc";
import { useWS } from "../../utils/websocket";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refresh: () => void;
  // type: 'create' | 'edit'
}

const INITIAL_VALUES = {
  amount: 0,
};

const AdminDepositModal = ({ open, handleClose, refresh }: IProps) => {
  const { adminUsers } = useContext(StoreContext);

  const { selectedUser } = adminUsers;

  const { send } = useWS();

  const initialValues = { ...INITIAL_VALUES };

  const updateBalance = useCallback(async (id: number, amount: number) => {
    return await send(
      "finance",
      "increase",
      {
        user_id: id,
        amount: amount,
      },
      "Balance successfully changed"
    );
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(0, "Amount should be greater than 0")
        .required("Amount is required"),
    }),
    onSubmit: async () => {
      if (!parseFloat(String(formik.values.amount))) {
        return;
      }
      let result;
      let touchedData = getTouchedData({ ...initialValues }, formik.values);

      if (Object.keys(touchedData).length) {
        [result] = await updateBalance(
          selectedUser.id,
          // @ts-ignore
          parseFloat(touchedData.amount)
        );
      }
      if (result) {
        refresh();
      }
      handleClose();
    },
  });

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"Deposit"}
      confirm={formik.submitForm}
    >
      <Box
        style={{
          width: 300,
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <NumericInput
            dataField="amount"
            label="Amount"
            formik={formik}
            min={0}
            precision={2}
          />
        </form>
        <ModalFooter
          loading={formik.isSubmitting}
          confirmAction={formik.submitForm}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default observer(AdminDepositModal);
