import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { getComparator, Order, stableSort } from "../../utils/etc";
import Loader from "../../components/Loader";
import { TABLE_PAGINATION } from "../../utils/constants";
import { useRef } from "react";

interface Data {
  id: number;
  rating: number;
  price: number;
  region_name: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "region_name",
    numeric: false,
    disablePadding: true,
    label: "Region",
    width: 200,
  },
  {
    id: "rating",
    numeric: true,
    disablePadding: false,
    label: "Rating",
    width: 50,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price (per day)",
    width: 100,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function CartCellComponent({
  id,
  addToCart,
  removeFromCart,
  inCart,
}: {
  id: number;
  addToCart: any;
  removeFromCart: any;
  inCart: boolean;
}) {
  return inCart ? (
    <TableCell align="center">
      <IconButton
        aria-label="add to shopping cart"
        onClick={removeFromCart}
        color="primary"
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
    </TableCell>
  ) : (
    <TableCell align="center">
      <IconButton
        aria-label="add to shopping cart"
        onClick={addToCart}
        color="primary"
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </TableCell>
  );
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: "10px" }}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ width: headCell.width }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  addToCart: any;
  removeFromCart: any;
  data: Data[];
  selected: number[];
  setSelected: (arrayOfIds: number[]) => void;
  inCart: number[];
  loading: boolean;
}

export default function MarketTable({
  addToCart,
  removeFromCart,
  data = [],
  selected,
  setSelected,
  inCart,
  loading,
}: IProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("price");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PAGINATION[0]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: 750,
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={(data || []).length}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const inCartSX = inCart.includes(row.id);
                  const isItemSelected = isSelected(row.id) && !inCartSX;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const addItemCart = () => {
                    addToCart(row);
                  };

                  const removeItemCart = () => {
                    removeFromCart(row.id);
                  };

                  return (
                    <TableRow
                      sx={{
                        // pointerEvents: inCartSX ? "none" : "auto",
                        backgroundColor: inCartSX
                          ? "rgba(163,255,157,0.2)"
                          : "background.paper",
                      }}
                      hover={!inCartSX}
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <CartCellComponent
                        inCart={inCartSX}
                        addToCart={addItemCart}
                        removeFromCart={removeItemCart}
                        id={row.id}
                      />

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {row.region_name}
                      </TableCell>
                      <TableCell align="center">{row.rating}</TableCell>
                      <TableCell align="center">{row.price}$</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          sx={{
            flex: 1,
            margin: "auto",
            alignItems: "center",
            justifyContent: "space-arround",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{
              position: "relative",
              left: 35,
            }}
          >
            {/*<IconButton*/}
            {/*  size="large"*/}
            {/*  onClick={addToCart}*/}
            {/*  color="primary"*/}
            {/*  aria-label="add to cart"*/}
            {/*  disabled={!Boolean(selected.length)}*/}
            {/*>*/}
            {/*  <AddShoppingCartIcon fontSize="inherit" />*/}
            {/*</IconButton>*/}
          </Grid>
          <Grid
            item
            sx={{
              marginLeft: "auto",
            }}
          >
            <TablePagination
              sx={{
                ".MuiButtonBase-root": {
                  color: "primary.main",
                },
                ".Mui-disabled": {
                  color: "primary.light",
                },
              }}
              rowsPerPageOptions={TABLE_PAGINATION}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
