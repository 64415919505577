import { FC, FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import NotFound from "../pages/404";
import AdminDevices from "../pages/AdminDevices";
import AdminUsers from "../pages/AdminUsers";
import Devices from "../pages/Devices";
import Login from "../pages/Login";
import Market from "../pages/Market";
import Register from "../pages/Register";
import RentedDevices from "../pages/RentedDevices";
import Statistic from "../pages/Statistic";
import Notifications from "../pages/Notifications";
import { RequireAdmin, RequireAuth } from "../utils/Auth";
import {
  ADMIN,
  ADMIN_DEVICES,
  ADMIN_USERS,
  DEVICES,
  GATES,
  LOGIN,
  MARKET,
  REGISTER,
  RENT,
  SIMBANKS,
  STATISTICS,
  NOTIFICATIONS,
} from "./constants";

export const Main: FC = () => {
  const authDecorator = (
    Page: FunctionComponent<any>,
    componentProps?: any
  ) => {
    return (
      <RequireAuth>
        <Page {...componentProps} />
      </RequireAuth>
    );
  };

  const adminDecorator = (
    Page: FunctionComponent<any>,
    componentProps?: any
  ) => {
    return (
      <RequireAdmin>
        <Page {...componentProps} />
      </RequireAdmin>
    );
  };

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Market />} />{" "}
        {/* Change later for Landing or some info page mb*/}
        <Route path={`/${MARKET}`} element={<Market />}>
          <Route
            path={`/${MARKET}/${GATES}`}
            element={<Market type={GATES} />}
          />
          <Route
            path={`/${MARKET}/${SIMBANKS}`}
            element={<Market type={SIMBANKS} />}
          />
        </Route>
        <Route path={`/${LOGIN}`} element={<Login />} />
        <Route path={`/${REGISTER}`} element={<Register />} />
        <Route path={`/${DEVICES}`} element={authDecorator(Devices)}>
          <Route
            path={`/${DEVICES}/${GATES}`}
            element={authDecorator(Devices, { type: GATES })}
          />
          <Route
            path={`/${DEVICES}/${SIMBANKS}`}
            element={authDecorator(Devices, { type: SIMBANKS })}
          />
        </Route>
        <Route path={`/${RENT}`} element={authDecorator(RentedDevices)}>
          <Route
            path={`/${RENT}/${GATES}`}
            element={authDecorator(RentedDevices, { type: GATES })}
          />
          <Route
            path={`/${RENT}/${SIMBANKS}`}
            element={authDecorator(RentedDevices, { type: SIMBANKS })}
          />
        </Route>
        <Route path={`/${STATISTICS}`} element={authDecorator(Statistic)}>
          <Route
            path={`/${STATISTICS}/real`}
            element={authDecorator(Statistic, { type: "real" })}
          />
          <Route
            path={`/${STATISTICS}/rented`}
            element={authDecorator(Statistic, { type: "rented" })}
          />
        </Route>
        <Route
          path={`/${NOTIFICATIONS}`}
          element={authDecorator(Notifications)}
        />
        <Route
          path={`/${ADMIN}/${ADMIN_USERS}`}
          element={adminDecorator(AdminUsers)}
        />
        <Route
          path={`/${ADMIN}/${ADMIN_DEVICES}`}
          element={adminDecorator(AdminDevices)}
        />
        <Route
          path={`/${ADMIN}`}
          element={<Navigate to={`/${ADMIN}/${ADMIN_USERS}`} replace />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
