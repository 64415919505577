import { Box, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import TableCard from "../components/primitives/TableCard";
import FilterList from "../containers/Tables/FilterList";
import Table from "../containers/Tables/MarketTable";
import { useWS } from "../utils/websocket";
import WarningModal from "../containers/Modals/WarningModal";
import { useLocation } from "react-router-dom";

const Market = (props: any) => {
  const { market, cart } = useContext(StoreContext);
  const [warningModal, setWaningModal] = useState<boolean>(false);

  const { getAPI, send, subscribe, unsubscribe } = useWS();

  const {
    data,
    filters,
    applyFilter,
    setSelectedChannels,
    selected,
    regions,
    touched,
    setMarketLoading,
    marketStateLoading,
  } = market;

  const refresh = useCallback(async () => {
    setMarketLoading(true);

    let regionsData = regions.length ? { region_id: regions } : {};
    let [data] = await send("channels", "market", {
      limit: 999999,
      offset: 0,
      ...regionsData,
    });
    market.setData(data);

    setMarketLoading(false);
  }, [regions]);

  const getRegions = async () => {
    let [data] = await getAPI("regions");
    market.setFilters(data);
  };

  useEffect(() => {
    async function fetch() {
      let regionsData = regions.length ? { region_id: regions } : {};
      let [data] = await send("channels", "market", {
        limit: 999999,
        offset: 0,
        ...regionsData,
      });
      market.setData(data);
    }

    let interval = setInterval(() => {
      fetch();
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, [regions.length]);

  useEffect(() => {
    async function fetch() {
      refresh();
    }

    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.stateChanged]);

  useEffect(() => {
    async function fetch() {
      refresh();
      getRegions();
    }

    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToCart = (channel: any) => {
    let cartRegion = [
      ...new Set((cart.cart || []).map((data) => data.region_id) || []),
    ][0];

    if (cartRegion && cartRegion !== channel.region_id) {
      setWaningModal(true);
      return;
    }
    cart.addToCart(channel);
    setSelectedChannels([]);
  };

  const inCart = cart.cart.map((data) => data.id);

  return (
    <Main path="/market">
      <Box
        component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ flexGrow: 1, width: 230 }}>
            <FilterList
              filters={filters}
              handleChange={applyFilter}
              onApply={refresh}
              touched={touched}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sx={{ flexGrow: 2, height: "100%" }}>
            <TableCard>
              <Table
                loading={marketStateLoading}
                data={data}
                addToCart={addToCart}
                removeFromCart={cart.removeFromCart}
                setSelected={setSelectedChannels}
                selected={selected}
                inCart={inCart}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
      {warningModal && (
        <WarningModal
          open={warningModal}
          handleClose={() => setWaningModal(false)}
          title={"Warning"}
          text={
            "Devices should have the same region. Please, select another channel"
          }
        />
      )}
    </Main>
  );
};

export default observer(Market);
