import { Box, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import TableCard from "../components/primitives/TableCard";
import List from "../containers/Tables/DeviceList";
import Table from "../containers/Tables/DeviceTable";
import { useWS } from "../utils/websocket";
import { ref } from "yup";

const Devices = () => {
  const { devices } = useContext(StoreContext);

  const {
    gates,
    setSelectedDevices,
    selected,
    selectedChannels,
    selectedChannelName,
    setGates,
    setChannels,
    setSelectedChannels,
    selectedChannelsData,
    gatesStateLoading,
    channelsStateLoading,
    setGatesLoading,
    setChanelsLoading,
  } = devices;

  const { send, deleteAPI, getAPI } = useWS();

  const getGates = useCallback(async () => {
    setGatesLoading(true);

    let [gates] = await getAPI("gates");
    setGates(gates);

    setGatesLoading(false);
  }, []);

  const getChannels = useCallback(async () => {
    setChanelsLoading(true);

    let [channels] = await getAPI("channels");
    setChannels(channels);

    setChanelsLoading(false);
  }, []);

  const refresh = async () => {
    await getGates();
    await getChannels();
  };

  const removeDevice = useCallback(async (id: number) => {
    return await deleteAPI(
      "gates",
      {
        id,
      },
      "Device successfully removed"
    );
  }, []);

  const terminateGateRent = useCallback(async (id: number) => {
    return await send(
      "gates",
      "terminate_rent",
      {
        id,
      },
      "Rent successfully terminated"
    );
  }, []);

  useEffect(() => {
    async function fetch() {
      await refresh();
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main path="/devices">
      <Box
        // component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ flexGrow: 1, width: 420 }}>
            <TableCard>
              <List
                loading={gatesStateLoading}
                refresh={refresh}
                remove={removeDevice}
                terminateRent={terminateGateRent}
                data={gates}
                selectData={setSelectedDevices}
                selected={selected}
              />
            </TableCard>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sx={{ flexGrow: 7 }}>
            <TableCard>
              <Table
                loading={channelsStateLoading}
                refresh={getChannels}
                channelName={selectedChannelName}
                data={selectedChannels}
                select={setSelectedChannels}
                selected={selectedChannelsData}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default observer(Devices);
