import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import {
  ADMIN,
  ADMIN_DEVICES,
  ADMIN_USERS,
  DEVICES,
  LOGIN,
  MARKET,
  REGISTER,
  RENT,
} from "../routes/constants";
import { RequireAuthLink, RequireAdminLink, useAuth } from "../utils/Auth";
import Cart from "./Cart";
import Link from "./primitives/Link";
import UserMenu from "./UserMenu";
import HeaderMenu from "./HeaderMenu";

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const Header = observer((props: any) => {
  let navigate = useNavigate();

  let match = useMatch("/admin/*");

  const auth = useAuth();

  const links = [
    <Link key={`/${MARKET}/gates`} to={`/${MARKET}/gates`} label={"Market"} />,
    <RequireAuthLink key={`/${DEVICES}/gates`}>
      <Link to={`/${DEVICES}/gates`} label={"My devices"} />
    </RequireAuthLink>,
    <RequireAuthLink key={`/${RENT}/gates`}>
      <Link to={`/${RENT}/gates`} label={"Rented devices"} />
    </RequireAuthLink>,
  ];

  const adminLinks = [
    <RequireAdminLink key={`/${ADMIN}/${ADMIN_USERS}`}>
      <Link to={`/${ADMIN}/${ADMIN_USERS}`} label={"Users"} />
    </RequireAdminLink>,
    <RequireAdminLink key={`/${ADMIN}/${ADMIN_DEVICES}`}>
      <Link to={`/${ADMIN}/${ADMIN_DEVICES}`} label={"Devices"} />
    </RequireAdminLink>,
  ];

  const authedLinks = (
    <>
      <HeaderMenu>{match ? adminLinks : links}</HeaderMenu>
      <Cart />
      {auth.isAuth ? <UserMenu /> : <Link to={`/${LOGIN}`} label={"Sign in"} />}
    </>
  );

  let linksWithoutAuth = {
    login: <Link to={`/${REGISTER}`} label={"Sign up"} />,
    register: <Link to={`/${LOGIN}`} label={"Sign in"} />,
  }[props.loginPage as string];

  const indexRoute = useCallback((event: any) => {
    navigate("/market/gates");
  }, []);

  return (
    <>
      <NavbarRoot>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: 64,
            alignItems: "center",
            px: 2,
          }}
        >
          {/*<Toolbar*/}
          {/*  disableGutters*/}
          {/*  sx={{*/}
          {/*    height: 64,*/}
          {/*    left: 0,*/}
          {/*    px: 5,*/}
          {/*  }}*/}
          {/*>*/}
          <Box
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1 auto",
            }}
          >
            <Typography
              color="textPrimary"
              variant="h2"
              onClick={indexRoute}
              sx={{
                width: "max-content",
                // mx: 16,
                color: "secondary.main",
                cursor: "pointer",
                textTransform: "uppercase",
                letterSpacing: {
                  xs: "0.9rem",
                  md: "1rem",
                  lg: "1.1rem",
                },
                fontSize: {
                  xs: "1.5rem",
                  md: "1.6rem",
                  lg: "1.7rem",
                },
              }}
            >
              VoIP Rentals
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {props.loginPage ? linksWithoutAuth : authedLinks}
          {/*</Toolbar>*/}
        </Box>
      </NavbarRoot>
    </>
  );
});

export default Header;
