import { Container, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import NavTabs from "./primitives/NavTabs";

const WorkspaceRoot = styled("div")(({ theme }) => ({
  paddingTop: 80,
}));

const Workspace = (props: any) => {
  const { children } = props;

  const navComponent = function (disable: boolean): ReactNode {
    if (disable && props.helper)
      return (
        <Grid item sx={{ width: 470 }}>
          {props.helper}
        </Grid>
      );

    if (disable) return null;

    return (
      <Grid item>
        <NavTabs path={props.path} />
      </Grid>
    );
  };

  return (
    <>
      <WorkspaceRoot>
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {navComponent(props.disableNav)}
            <Grid
              item
              sx={{
                minWidth: 1152,
                position: "absolute",
                top: 128,
                height: "calc(100vh - 146px)",
                minHeight: 500,
              }}
            >
              <Paper sx={{ height: "100%" }}>{children}</Paper>
            </Grid>
          </Grid>
        </Container>
      </WorkspaceRoot>
    </>
  );
};

export default Workspace;
