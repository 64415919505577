import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import {
  dateCellRender,
  formatDuration,
  getComparator,
  Order,
  stableSort,
} from "../../utils/etc";
import Loader from "../../components/Loader";
import { TABLE_PAGINATION } from "../../utils/constants";
import { useEffect, useRef } from "react";

interface Data {
  create_time: string;
  channel_name: string;
  active_time: number;
  rent_time: number;
  price: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "create_time",
    numeric: false,
    disablePadding: true,
    label: "Create Time",
  },
  {
    id: "channel_name",
    numeric: false,
    disablePadding: true,
    label: "Channel Name",
  },
  {
    id: "active_time",
    numeric: true,
    disablePadding: false,
    label: "Active Time",
  },
  {
    id: "rent_time",
    numeric: true,
    disablePadding: false,
    label: "Rent time",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: Data[];
  loading: boolean;
}

export default function StatisticTable({ data = [], loading }: IProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("channel_name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PAGINATION[0]);

  useEffect(() => {
    setPage(0);
  }, [data.length]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: 750,
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={false}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        sx={{
                          maxWidth: 120,
                        }}
                        align="center"
                      >
                        {dateCellRender(row.create_time)}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 120,
                        }}
                        align="center"
                      >
                        {row.channel_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 60,
                        }}
                        align="center"
                      >
                        {formatDuration(row.active_time)}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 60,
                        }}
                        align="center"
                      >
                        {formatDuration(row.rent_time)}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 120,
                        }}
                        align="center"
                      >
                        {row.price}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          sx={{
            flex: 1,
            margin: "auto",
            alignItems: "center",
            justifyContent: "space-arround",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{
              marginLeft: "auto",
            }}
          >
            <TablePagination
              sx={{
                ".MuiButtonBase-root": {
                  color: "primary.main",
                },
                ".Mui-disabled": {
                  color: "primary.light",
                },
              }}
              rowsPerPageOptions={TABLE_PAGINATION}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
