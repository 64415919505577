import { Box } from "@mui/system";
import { useState } from "react";
import Modal from "../../components/Modal";
import { Button } from "@mui/material";

interface IProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  text: string;
}

const WarningModal = ({ open, handleClose, title, text }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal
      color="warning.light"
      open={open}
      handleClose={handleClose}
      title={title}
      confirm={handleClose}
    >
      <Box
        style={{
          minHeight: 150,
          // minWidth: 350,
          width: 360,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            margin: "auto",
          }}
        >
          {text}
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Button
            sx={{
              py: 2,
              margin: "auto",
              marginTop: 4,
              width: 140,
            }}
            color="info"
            fullWidth
            size="small"
            variant="outlined"
            onClick={handleClose}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WarningModal;
