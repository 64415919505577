import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import * as Yup from "yup";
import { useWS } from "../utils/websocket";
import NumericInput from "./primitives/NumericInput";
import { StoreContext } from "../App";
import { getTouchedData } from "../utils/etc";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

interface IProps {
  getFormData: any;
  finish: any;
  isLoading: boolean;
  prevStep: () => void;
}

const LinkGateForm = ({ getFormData, finish, isLoading, prevStep }: IProps) => {
  const { send } = useWS();

  const { virtualDevices } = useContext(StoreContext);
  const { selectedVirtualGate } = virtualDevices;

  const linkGate = useCallback(async (id: number, data: any) => {
    return await send(
      "virtual_gates",
      "link_with_goaf",
      {
        id,
        ...data,
      },
      "Virtual Gate successfully linked"
    );
  }, []);

  const initialValues = {
    remote_server: "",
    remote_server_port: "",
    remote_server_id: "",
    remote_server_key: "",
  };

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      remote_server: Yup.string()
        .max(255, "Remote server must be less than 256 characters")
        .required("Remote server is required")
        .trim("Remote server is not valid.")
        .strict(true)
        .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
          message: "Invalid IP address",
          excludeEmptyString: true,
        })
        .test(
          "ipAddress",
          "IP address value should be less or equal to 255",
          (value: any) => {
            if (value === undefined || value.trim() === "") return true;
            return (
              value.split(".").find((i: any) => parseInt(i) > 255) === undefined
            );
          }
        ),
      remote_server_port: Yup.number().required(
        "Remote server port is required"
      ),
      remote_server_id: Yup.string()
        .max(255, "Remote server id must be less than 256 characters")
        .required("Remote server ID is required")
        .trim("Remote server id is not valid.")
        .strict(true)
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Remote server id is not valid",
        }),
      remote_server_key: Yup.string()
        .max(255, "Remote server key must be less than 256 characters")
        .required("Remote server key is required")
        .trim("Remote server key is not valid.")
        .strict(true)
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Remote server key is not valid",
        }),
    }),
    onSubmit: async () => {
      // let result;
      let touchedData = getTouchedData({ ...initialValues }, formik.values);

      await finish({
        ...touchedData,
        // @ts-ignore
        remote_server_port: parseInt(touchedData.remote_server_port),
      });
      //
      // if (Object.keys(touchedData).length) {
      //   [result] = await linkGate(selectedVirtualGate.id, touchedData);
      // }
      // if (result) {
      //   refresh();
      // }
      // handleClose();
    },
  });

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        minHeight: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            style={{
              minHeight: 70,
              margin: 0,
            }}
            size="small"
            error={Boolean(
              formik.touched.remote_server && formik.errors.remote_server
            )}
            fullWidth
            helperText={
              formik.touched.remote_server && formik.errors.remote_server
            }
            label="remote_server"
            margin="normal"
            name="remote_server"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="name"
            value={formik.values.remote_server}
            variant="outlined"
          />
          <NumericInput
            dataField="remote_server_port"
            label="remote_server_port"
            formik={formik}
            min={0}
            // max={selectedUser.balance}
          />
          <TextField
            style={{
              minHeight: 70,
              margin: 0,
            }}
            size="small"
            error={Boolean(
              formik.touched.remote_server_id && formik.errors.remote_server_id
            )}
            fullWidth
            helperText={
              formik.touched.remote_server_id && formik.errors.remote_server_id
            }
            label="remote_server_id"
            margin="normal"
            name="remote_server_id"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="name"
            value={formik.values.remote_server_id}
            variant="outlined"
          />
          <TextField
            style={{
              minHeight: 70,
              margin: 0,
            }}
            size="small"
            error={Boolean(
              formik.touched.remote_server_key &&
                formik.errors.remote_server_key
            )}
            fullWidth
            helperText={
              formik.touched.remote_server_key &&
              formik.errors.remote_server_key
            }
            label="remote_server_key"
            margin="normal"
            name="remote_server_key"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="name"
            value={formik.values.remote_server_key}
            variant="outlined"
          />
        </form>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          marginTop: "100px",
          alignContent: "flex-end",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={prevStep}>Back</Button>
        <LoadingButton
          sx={{
            width: "40%",
          }}
          color={"success"}
          variant={"contained"}
          loading={isLoading}
          fullWidth
          onClick={(e) => formik.handleSubmit()}
        >
          Finish
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default observer(LinkGateForm);
