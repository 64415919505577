import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

interface IProps {
  label: string;
  onChange: (date: any) => void;
  value: Dayjs | null;
}

export default function BasicDatePicker({ label, onChange, value }: IProps) {
  const handleChange = (date: Dayjs | null) => {
    onChange(date);
  };

  const clearDate = () => {
    onChange(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <DatePicker
          views={["day"]}
          label={label}
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} size={"small"} helperText={null} />
          )}
        />
        {value ? (
          <Box
            sx={{
              width: 0,
              position: "absolute",
              left: "65%",
            }}
          >
            <IconButton aria-label="delete" size="small" onClick={clearDate}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </LocalizationProvider>
  );
}
