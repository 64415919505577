import { makeAutoObservable } from "mobx";

type UserAdmin = {
  id: number;
  email: string;
  balance: number;
};

type DeviceAdmin = {
  id: number;
  remote_server_id: string;
  virtual_channel_name: string;
  real_channel_name: string;
  active: boolean;
  linked: boolean;
  // real_channel_id: number;
  virtual_channel_id: number;
};

type Transaction = {
  id: number;
  transaction_id: string;
  timestamp: string;
  amount: number;
};

type DeviceStore = {
  [id: number]: Array<DeviceAdmin>;
};

type TransactionStore = {
  [id: number]: Array<Transaction>;
};

export default class AdminUserStore {
  users: Array<UserAdmin> = [];
  selected: number[] = [];
  devices: DeviceStore = {};
  transactions: TransactionStore = {};
  usersStateLoading: boolean = false;
  devicesStateLoading: boolean = false;
  transactionsStateLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUsers = (data: any) => {
    if (!data) return;

    this.users = data;
  };

  setDevices = (id: number, data: Array<DeviceAdmin>) => {
    if (!data) return;

    this.devices[id] = data;
  };

  setTransaction = (id: number, data: Array<Transaction>) => {
    if (!data) return;

    this.transactions[id] = data;
  };

  setSelectedUsers = (arrayOfIds: number[]) => {
    this.selected = [...arrayOfIds];
  };

  setUsersStateLoading = (state: boolean = true) => {
    this.usersStateLoading = state;
  };

  setDevicesStateLoading = (state: boolean = true) => {
    this.devicesStateLoading = state;
  };

  setTransactionsStateLoading = (state: boolean = true) => {
    this.transactionsStateLoading = state;
  };

  get selectedUser() {
    return (this.users || []).filter((row) =>
      this.selected.includes(row.id)
    )[0];
  }

  get getDevicesData() {
    return this.selected.length ? this.devices[this.selected[0]] || [] : [];
  }

  get getTransactionData() {
    return this.selected.length
      ? this.transactions[this.selected[0]] || []
      : [];
  }
}
