import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface IProps {
  title: string | JSX.Element;
  children: any;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    // maxWidth: 250,
    fontSize: 14,
  },
}));

export default function ToolTip({ title, children }: IProps) {
  return (
    <CustomTooltip title={title} arrow placement="top">
      {children}
    </CustomTooltip>
  );
}
