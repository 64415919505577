import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import TableCard from "../components/primitives/TableCard";
import SearchComponent from "../components/SearchComponent";
import List from "../containers/Tables/AdminUserList";
import Table from "../containers/Tables/AdminUsersTable";
import { useWS } from "../utils/websocket";

const AdminUsers = () => {
  const { adminUsers } = useContext(StoreContext);
  const {
    users,
    setUsers,
    setSelectedUsers,
    selected,
    setTransaction,
    getTransactionData,
    usersStateLoading,
    setUsersStateLoading,
    transactionsStateLoading,
    setTransactionsStateLoading,
  } = adminUsers;
  const { send, deleteAPI, getAPI } = useWS();
  const [searchKey, setSearchKey] = useState<string>("");

  const getUsers = useCallback(
    async (searchKey: string) => {
      setUsersStateLoading(true);

      let param = searchKey.length ? { name_or_email_search: searchKey } : {};
      let [users, error] = await send("users", "list", {
        limit: 999999,
        offset: 0,
        ...param,
      });
      setUsers(users);
      setUsersStateLoading(false);
    },
    [searchKey]
  );

  const getTransactionsRequest = useCallback(async () => {
    let userID = selected.length && selected[0];

    if (!userID) return;
    setTransactionsStateLoading(true);

    let [transactions, error] = await send("finance", "transactions", {
      limit: 999999,
      offset: 0,
      user_id: userID,
    });

    setTransaction(userID, transactions);
    setTransactionsStateLoading(false);
  }, [selected]);

  const refresh = useCallback(
    async (search: string = "") => {
      await getUsers(search || searchKey);
      await getTransactionsRequest();
    },
    [searchKey, selected]
  );

  const refreshWithClearing = useCallback(
    async (searchKey: string = "") => {
      setSelectedUsers([]);
      await getUsers(searchKey);
    },
    [searchKey]
  );

  useEffect(() => {
    async function fetch() {
      await refresh();
    }
    fetch();

    return () => {
      setSelectedUsers([]);
    };
  }, []);

  useEffect(() => {
    getTransactionsRequest();
  }, [selected]);

  const searchComponent = () => {
    return (
      <SearchComponent
        value={searchKey}
        onChange={setSearchKey}
        label="Search by name or email"
        apply={refreshWithClearing}
      />
    );
  };

  return (
    <Main disableNav helper={searchComponent()}>
      <Box
        // component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ flexGrow: 1, width: 420 }}>
            <TableCard>
              <List
                loading={usersStateLoading}
                refresh={refresh}
                data={users}
                selectData={setSelectedUsers}
                selected={selected}
              />
            </TableCard>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sx={{ flexGrow: 7 }}>
            <TableCard>
              <Table
                loading={transactionsStateLoading}
                data={getTransactionData}
                select={() => {}}
                selected={[]}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default observer(AdminUsers);
