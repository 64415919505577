export const DEVICE_PATH = "/devices";

export const GET = "list";
export const UPDATE = "update";
export const DELETE = "delete";
export const CREATE = "create";

export const VIRTUAL_DATA = [
  {
    id: 1,
    name: "Tes2t",
    region: "Europe",
  },
  {
    id: 2,
    name: "Test2",
    region: "Europe",
  },
];

export const VIRTUAL_CHANNELS = [
  {
    id: 1,
    position: 1,
    active: true,
    price: 11,
    gateID: 1,
  },
  {
    id: 2,
    position: 2,
    active: true,
    price: 11,
    gateID: 1,
  },
  {
    id: 3,
    position: 3,
    active: true,
    price: 11,
    gateID: 1,
  },
  {
    id: 4,
    position: 4,
    active: true,
    price: 11,
    gateID: 2,
  },
];

export const TABLE_PAGINATION = [15, 50, 100];

export const ADM_KEY = "tgk";
export const ADM_VALUE = "a2614727bca6549236c470392e1e122ac135083b1ecb30ac";

export const ReCAPTCHA_SITE_KEY = "6LcL84MlAAAAAJIXafivfrZim7zfwzAgpijMDc81";
export const ReCAPTCHA_SITE_KEY_TEST =
  "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
