import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useCallback, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContextMenu from "../../components/ContextMenu";
import GateLink from "../../components/GateLink";
import {
  dateCellRender,
  getComparator,
  Order,
  stableSort,
} from "../../utils/etc";
import Loader from "../../components/Loader";
import { TABLE_PAGINATION } from "../../utils/constants";
import CircleIcon from "@mui/icons-material/Circle";
import dayjs from "dayjs";

type Data = {
  id: number;
  active: string;
  timestamp: string;
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "active",
    numeric: false,
    disablePadding: true,
    label: "Active",
    width: 50,
  },
  {
    id: "timestamp",
    numeric: false,
    disablePadding: true,
    label: "Date",
    width: 140,
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
            align="center"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/*<TableSortLabel*/}
            {/*  active={orderBy === headCell.id}*/}
            {/*  direction={orderBy === headCell.id ? order : "asc"}*/}
            {/*  onClick={createSortHandler(headCell.id)}*/}
            {/*>*/}
            {headCell.label}
            {/*  {orderBy === headCell.id ? (*/}
            {/*    <Box component="span" sx={visuallyHidden}>*/}
            {/*      {order === "desc" ? "sorted descending" : "sorted ascending"}*/}
            {/*    </Box>*/}
            {/*  ) : null}*/}
            {/*</TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: Data[];
  loading: boolean;
}

export default function DeviceLogsList({ data = [], loading }: IProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("timestamp");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PAGINATION[0]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: "50%",
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: "50%" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {data.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                const voidMethod = () => {};

                return (
                  <TableRow
                    role="checkbox"
                    tabIndex={-1}
                    key={`${row.active}__${row.timestamp}`}
                  >
                    <TableCell
                      sx={{
                        maxWidth: 50,
                        minWidth: 50,
                        px: 3,
                      }}
                      id={labelId}
                      align="center"
                      padding="normal"
                    >
                      <CircleIcon
                        sx={{
                          color: row.active
                            ? "rgba(113, 255, 122, 0.8)"
                            : "rgba(255, 113, 113, 0.8)",
                          fontSize: 12,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 160,
                        minWidth: 160,
                      }}
                      id={labelId}
                      align="center"
                      padding="normal"
                    >
                      {dateCellRender(row.timestamp)}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<Grid*/}
        {/*  container*/}
        {/*  sx={{*/}
        {/*    flex: 1,*/}
        {/*    margin: "auto",*/}
        {/*    alignItems: "center",*/}
        {/*    justifyContent: "space-arround",*/}
        {/*    width: "100%",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    sx={{*/}
        {/*      marginLeft: "auto",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <TablePagination*/}
        {/*      sx={{*/}
        {/*        ".MuiButtonBase-root": {*/}
        {/*          color: "primary.main",*/}
        {/*        },*/}
        {/*        ".Mui-disabled": {*/}
        {/*          color: "primary.light",*/}
        {/*        },*/}
        {/*      }}*/}
        {/*      rowsPerPageOptions={TABLE_PAGINATION}*/}
        {/*      component="div"*/}
        {/*      count={data.length}*/}
        {/*      rowsPerPage={rowsPerPage}*/}
        {/*      page={page}*/}
        {/*      onPageChange={handleChangePage}*/}
        {/*      onRowsPerPageChange={handleChangeRowsPerPage}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Paper>
    </Box>
  );
}
