export const MARKET = "market";
export const RENT = "rent";
export const DEVICES = "devices";

export const LOGIN = "login";
export const REGISTER = "register";

export const GATES = "gates";
export const SIMBANKS = "simbanks";

export const STATISTICS = "statistic";

export const ADMIN = "admin";
export const ADMIN_USERS = "users";
export const ADMIN_DEVICES = "devices";

export const NOTIFICATIONS = "notifications";

export const STATUS_OPTIONS = [
  {
    id: 1,
    name: "Ready",
  },
  {
    id: 0,
    name: "Not ready",
  },
];
