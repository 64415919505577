import Workspace from "./Workspace";

const Main = (props: any) => {
  const { children } = props;
  return (
    <>
      <Workspace
        path={props.path}
        disableNav={props.disableNav}
        helper={props.helper}
      >
        {children}
      </Workspace>
    </>
  );
};

export default Main;
