import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import * as Yup from "yup";
import { StoreContext } from "../../App";

import Modal from "../../components/Modal";
import Dropdown from "../../components/primitives/Dropdown";
import ModalFooter from "../../components/primitives/ModalFooter";
import TextInput from "../../components/primitives/TextInput";
import { STATUS_OPTIONS } from "../../routes/constants";
import { getTouchedData } from "../../utils/etc";
import { useWS } from "../../utils/websocket";
import NumericInput from "../../components/primitives/NumericInput";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refresh: () => void;
  // type: 'create' | 'edit'
}

const INITIAL_VALUES = {
  price: 0,
  status: null,
};

const ChannelsModal = ({ open, handleClose, refresh }: IProps) => {
  const { devices } = useContext(StoreContext);

  const { selectedTableChannels } = devices;

  const { updateAPI } = useWS();

  let multipleEdit = selectedTableChannels.length > 1;

  let selectedData = multipleEdit
    ? { ...INITIAL_VALUES, price: "" }
    : selectedTableChannels[0];

  const initialValues = { ...INITIAL_VALUES, ...selectedData };

  const updateChannel = useCallback(async (id: number[], data: any) => {
    return await updateAPI(
      "channels",
      {
        id,
        ...data,
      },
      "Channel successfully changed"
    );
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      price: multipleEdit
        ? Yup.number()
        : Yup.number()
            .positive("Price should be greater than 0")
            .required("Price is required"),
      status: multipleEdit
        ? Yup.string().nullable()
        : Yup.number().required("Status is required"),
    }),
    onSubmit: async () => {
      let result;
      let touchedData = getTouchedData(initialValues, formik.values);
      if (Object.keys(touchedData).length) {
        let channelsIDs = selectedTableChannels.map((i) => i.id),
          promiseArr: PromiseLike<any>[] = [],
          resultArray;

        channelsIDs.forEach((channelsID) => {
          promiseArr.push(updateChannel(channelsID, touchedData));
        });
        resultArray = await Promise.all(promiseArr);
        resultArray.forEach(([i]) => {
          if (i) result = true;
        });

        if (result) {
          refresh();
          handleClose();
        }
      } else {
        handleClose();
      }
    },
  });

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"Edit Channel"}
      confirm={formik.submitForm}
    >
      <Box
        style={{
          width: 320,
          minHeight: 300,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <NumericInput
            formik={formik}
            dataField="price"
            label="Price"
            min={0}
            precision={2}
          />
          <Dropdown
            formik={formik}
            dataField="status"
            options={STATUS_OPTIONS}
            value={formik.values.status}
            label="Status"
            helperText={formik.errors.status}
          />
        </form>
        <ModalFooter
          loading={formik.isSubmitting}
          confirmAction={formik.submitForm}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default observer(ChannelsModal);
