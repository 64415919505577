import { Box } from "@mui/system";

import webtgateway from "../images/webt-gateway.png";
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RemotePart = ({
  value,
  label,
}: {
  value: string | number;
  label: string;
}) => {
  return (
    <FormControl variant="standard" margin={"normal"}>
      <InputLabel htmlFor={`form_id_${label}`}>{label}</InputLabel>
      <Input
        id={`form_id_${label}`}
        type={"text"}
        value={value}
        disabled
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label={`copy_${label}`}>
              <CopyToClipboard text={String(value)}>
                <ContentCopyIcon color="primary" sx={{ fontSize: 15 }} />
              </CopyToClipboard>
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

type Options = {
  remote_server_ip: string;
  remote_server_port: string | number;
  remote_server_id: string;
  remote_server_key: string;
};

const RemoteField = ({ options }: { options: Options }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      marginY={2}
      alignItems={"center"}
      position={"relative"}
    >
      <Box
        sx={[
          {
            "&::before": {
              content: "''",
              display: "block",
              width: "6px",
              height: "6px",
              borderRadius: "100%",
              backgroundColor: "#10B981",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            },
          },
          {
            borderTop: "solid 1px #10B981",
            height: "1px",
            width: 126,
            position: "absolute",
            left: "165px",
            bottom: "125px",
          },
        ]}
      ></Box>
      <Box sx={{ maxWidth: "100%" }}>
        <img src={webtgateway} alt="Gateway web interface fields" />
      </Box>
      <Box marginLeft={2}>
        <Box sx={{ border: "solid 1px #10B981", borderRadius: 1 }} padding={1}>
          <RemotePart
            value={options.remote_server_ip}
            label="Remote server ip"
          />
          <RemotePart
            value={options.remote_server_port}
            label="Remote server port"
          />
          <RemotePart
            value={options.remote_server_id}
            label="Remote server id"
          />
          <RemotePart
            value={options.remote_server_key}
            label="Remote server key"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RemoteField;
