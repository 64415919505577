import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import React, { useRef, useState } from "react";
import ContextMenu from "../../components/ContextMenu";
import { getComparator, Order, stableSort } from "../../utils/etc";
import Loader from "../../components/Loader";
import DeviceModal from "../Modals/DeviceModal";
import DeviceLogsModal from "../Modals/DeviceLogsModal";
import FeedIcon from "@mui/icons-material/Feed";

interface Data {
  id: number;
  virtual_channel_name: string;
  active: boolean;
  linked: boolean;
  real_channel_name: string;
  remote_server_id: string;
  // real_channel_id: number;
  virtual_channel_id: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "virtual_channel_name",
    numeric: false,
    disablePadding: true,
    label: "Virtual channel",
    width: 85,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
    width: 40,
  },
  {
    id: "linked",
    numeric: true,
    disablePadding: false,
    label: "Linked",
    width: 40,
  },
  {
    id: "real_channel_name",
    numeric: true,
    disablePadding: false,
    label: "Real channel",
    width: 90,
  },
  {
    id: "remote_server_id",
    numeric: true,
    disablePadding: false,
    label: "Remote server ID",
    width: 80,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              // sx={{
              //   "& .MuiTableSortLabel-icon": {
              //     fontSize: "12px",
              //     m: "1px",
              //   },
              // }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: Data[];
  select: any;
  selected: number | null;
  loading: boolean;
}

export default function AdminDevicesTable({
  data,
  select,
  selected,
  loading,
}: IProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("virtual_channel_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deviceLogsModal, setLogsDeviceModal] = useState<boolean>(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected: readonly number[] = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   select(newSelected);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  // const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const onContextMenuTrigger = (event: React.MouseEvent, id: number) => {
    // if (!selected.includes(id)) {
    //   select([id]);
    // }
    select(id);
  };

  const openDeviceLogsModal = () => {
    setLogsDeviceModal(true);
  };

  const closeDeviceLogsModal = () => {
    setLogsDeviceModal(false);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: "50%",
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: "50%" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={[].length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {stableSort(data, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = row.id === selected;
                  const onClickMethod = (event: React.MouseEvent) => {
                    return select([row.id]);
                  };

                  return (
                    <ContextMenu
                      key={`${row.virtual_channel_name}-${row.real_channel_name}-${index}`}
                      items={[
                        {
                          name: "Logs",
                          method: openDeviceLogsModal,
                          icon: FeedIcon,
                        },
                      ]}
                      callback={(event) =>
                        onContextMenuTrigger(event, row.virtual_channel_id)
                      }
                    >
                      <TableRow
                        hover
                        onClick={onClickMethod}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          sx={{
                            maxWidth: 85,
                          }}
                          align="center"
                        >
                          {row.virtual_channel_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 40,
                          }}
                          align="center"
                        >
                          <CircleIcon
                            sx={{
                              color: row.active
                                ? "rgba(113, 255, 122, 0.8)"
                                : "rgba(255, 113, 113, 0.8)",
                              fontSize: 12,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 40,
                          }}
                          align="center"
                        >
                          <CircleIcon
                            sx={{
                              color: row.linked
                                ? "rgba(113, 255, 122, 0.8)"
                                : "rgba(255, 113, 113, 0.8)",
                              fontSize: 12,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 70,
                          }}
                          align="center"
                        >
                          {row.real_channel_name}
                        </TableCell>
                        <TableCell
                          title={row.remote_server_id}
                          sx={{
                            maxWidth: 80,
                          }}
                          align="center"
                        >
                          {row.remote_server_id}
                        </TableCell>
                      </TableRow>
                    </ContextMenu>
                  );
                }
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * 15,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {deviceLogsModal ? (
        <DeviceLogsModal
          open={deviceLogsModal}
          handleClose={closeDeviceLogsModal}
          selected={selected}
        />
      ) : null}
    </Box>
  );
}
