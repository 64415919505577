import { isEqual } from "lodash";
import { makeAutoObservable } from "mobx";

type Notification = {
  message: string;
  timestamp: string;
  id: number;
};

export default class NotificationsStore {
  notifications: Array<Notification> = [];
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setNotifications = (data: any) => {
    if (!data) return;

    this.notifications = data;
  };

  setLoadingState = (state: boolean = true) => {
    this.loading = state;
  };
}
