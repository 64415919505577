import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { StoreContext } from "../../App";

import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";
import RemoteField from "../../components/RemoteField";
import { getTouchedData } from "../../utils/etc";
import { useWS } from "../../utils/websocket";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refresh: () => void;
  type: "create" | "edit";
}

const VALUES = {
  name: "",
  region_id: "",
  simserver_password: "",
  simserver_login: "",
  web_login: "",
  web_password: "",
};

const DeviceModal = ({ open, handleClose, refresh, type }: IProps) => {
  const { createAPI, updateAPI, send } = useWS();

  const [remoteParams, setRemoteParams] = useState({
    remote_server_ip: "",
    remote_server_port: "",
    remote_server_id: "",
    remote_server_key: "",
  });
  const { devices, options } = useContext(StoreContext);
  const { selectedGate } = devices;

  useEffect(() => {
    const getRegions = async () => {
      let [data] = await send("regions", "list", {
        limit: 999999,
        offset: 0,
      });
      options.setOption("regions", data);
    };
    getRegions();

    if (type === "create") {
      const getRemoteParams = async () => {
        let [params] = await send("gates", "generate_access", {});
        setRemoteParams(params);
      };
      getRemoteParams();
    }
  }, []);

  const initialValues =
    type === "edit"
      ? {
          name: selectedGate.name,
          region_id: selectedGate.region_id,
          simserver_password: selectedGate.simserver_password,
          simserver_login: selectedGate.simserver_login,
          web_login: selectedGate.web_login,
          web_password: selectedGate.web_password,
        }
      : { ...VALUES };

  const addDevice = useCallback(async (data: any) => {
    return await createAPI(
      "gates",
      {
        ...data,
      },
      "Device successfully added"
    );
  }, []);

  const updateDevice = useCallback(async (id: number, data: any) => {
    return await updateAPI(
      "gates",
      {
        id,
        ...data,
      },
      "Device successfully changed"
    );
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, "Name must be less than 256 characters")
        .required("Name is required")
        .trim("Name is not valid.")
        .strict(true)
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Name is not valid",
        }),
      region_id: Yup.number().required("Region is required"),
      web_login: Yup.string()
        .max(255, "Web login must be less than 256 characters")
        .required("Web login is required")
        .trim("Web login is not valid.")
        .strict(true)
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Web login is not valid",
        }),
      web_password: Yup.string()
        .max(255, "Web password must be less than 256 characters")
        .required("Web password is required")
        .trim("Web password is not valid.")
        .matches(/^\S+$/, {
          excludeEmptyString: false,
          message: "Web password is not valid",
        })
        .strict(true),
    }),
    onSubmit: async () => {
      let result;
      switch (type) {
        case "create":
          const { name, region_id, web_login, web_password } = formik.values;

          [result] = await addDevice({
            name,
            region_id,
            web_login,
            web_password,
            remote_server_id: remoteParams.remote_server_id,
          });
          break;
        case "edit":
          let touchedData = getTouchedData(selectedGate, formik.values);
          if (Object.keys(touchedData).length) {
            [result] = await updateDevice(selectedGate.id, touchedData);
          }
          break;
      }
      if (result) {
        refresh();
        handleClose();
      }
    },
  });

  const title = `${type.toUpperCase()} GATE`;

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={title}
      confirm={formik.submitForm}
    >
      <Box
        style={{
          width: 500,
          minHeight: 300,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row">
            <Grid item sx={{ width: "100%" }}>
              <Grid
                container
                direction={"row"}
                sx={{ width: "100%", justifyContent: "space-between" }}
              >
                <Grid item sx={{ flexGrow: 1, maxWidth: 240 }}>
                  <TextField
                    style={{
                      minHeight: 70,
                      margin: 0,
                    }}
                    size="small"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    // @ts-ignore
                    helperText={formik.touched.name && formik.errors.name}
                    label="Name"
                    margin="normal"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="name"
                    value={formik.values.name}
                    variant="outlined"
                  />
                </Grid>
                {type === "create" && (
                  <Grid item sx={{ flexGrow: 1, maxWidth: 240 }}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.touched.region_id && formik.errors.region_id
                      )}
                      size="small"
                    >
                      <InputLabel id="device_modal-region-label">
                        Region
                      </InputLabel>
                      <Select
                        labelId="device_modal-region-label"
                        id="device_modal-region"
                        value={formik.values.region_id}
                        label="Region"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="region_id"
                      >
                        {options
                          .getOption("regions")
                          .map((option: any, index) => {
                            return (
                              <MenuItem key={index} value={option.id}>
                                {option.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {Boolean(
                        formik.touched.region_id && formik.errors.region_id
                      ) && <FormHelperText>Region is required</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                direction={"row"}
                sx={{ width: "100%", justifyContent: "space-between" }}
              >
                <Grid item sx={{ flexGrow: 1, maxWidth: 240 }}>
                  <TextField
                    style={{
                      minHeight: 70,
                      margin: 0,
                    }}
                    size="small"
                    error={Boolean(
                      formik.touched.web_login && formik.errors.web_login
                    )}
                    fullWidth
                    // @ts-ignore
                    helperText={
                      formik.touched.web_login && formik.errors.web_login
                    }
                    label="WEB login"
                    margin="normal"
                    name="web_login"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="name"
                    value={formik.values.web_login}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sx={{ flexGrow: 1, maxWidth: 240 }}>
                  <TextField
                    style={{
                      minHeight: 70,
                      margin: 0,
                    }}
                    size="small"
                    error={Boolean(
                      formik.touched.web_password && formik.errors.web_password
                    )}
                    fullWidth
                    // @ts-ignore
                    helperText={
                      formik.touched.web_password && formik.errors.web_password
                    }
                    label="WEB password"
                    margin="normal"
                    name="web_password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="name"
                    value={formik.values.web_password}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {type === "create" && <RemoteField options={remoteParams} />}
            </Grid>
          </Grid>
        </form>
        <ModalFooter
          loading={formik.isSubmitting}
          confirmAction={formik.submitForm}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default observer(DeviceModal);
