import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC } from "react";

type TextInputProps = TextFieldProps & {
  formik: any;
  label: string;
  dataField: string;
};

const TextInput: FC<TextInputProps> = ({
  formik,
  label,
  dataField,
  ...props
}) => {
  const isError = Boolean(
    formik.touched[dataField] && formik.errors[dataField]
  );
  return (
    <TextField
      style={{
        minHeight: 70,
        margin: 0,
      }}
      size="small"
      error={isError}
      fullWidth
      helperText={(isError && formik.errors[dataField]) || props.helperText}
      label={label}
      margin="normal"
      name={dataField}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      type="text"
      value={formik.values[dataField]}
      variant="outlined"
      {...props}
    />
  );
};

export default TextInput;
