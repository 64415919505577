import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useContext } from "react";
import * as Yup from "yup";
import { StoreContext } from "../../App";

import Modal from "../../components/Modal";
import ModalFooter from "../../components/primitives/ModalFooter";
import { getTouchedData } from "../../utils/etc";
import { useWS } from "../../utils/websocket";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refresh: () => void;
  // type: 'create' | 'edit'
}

const INITIAL_VALUES = {
  password: "",
};

const AdminChangePasswordModal = ({ open, handleClose, refresh }: IProps) => {
  const { adminUsers } = useContext(StoreContext);

  const { selectedUser } = adminUsers;

  const { send } = useWS();

  const initialValues = { ...INITIAL_VALUES };

  const updatePassword = useCallback(async (id: number, password: string) => {
    return await send(
      "users",
      "change_password",
      {
        user_id: id,
        new_password: password,
      },
      "Password successfully changed"
    );
  }, []);

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Length must be more than 7 symbols.")
        .max(255, "Password must be less than 256 characters")
        .matches(/^[a-zA-Z0-9=+!@#$%^&*()]*$/, {
          excludeEmptyString: false,
          message: "Password is not valid",
        }),
    }),
    onSubmit: async () => {
      let result;
      let touchedData = getTouchedData({ ...initialValues }, formik.values);

      if (Object.keys(touchedData).length) {
        // @ts-ignore
        [result] = await updatePassword(selectedUser.id, touchedData.password);
      }
      if (result) {
        refresh();
      }
      handleClose();
    },
  });

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"Change Password"}
      confirm={formik.submitForm}
    >
      <Box
        style={{
          width: 380,
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            style={{
              minHeight: 70,
              margin: 0,
            }}
            size="small"
            error={Boolean(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={
              Boolean(formik.touched.password && formik.errors.password) &&
              formik.errors.password
            }
            label="Password"
            margin="normal"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="string"
            value={formik.values.password}
            variant="outlined"
          />
        </form>
        <ModalFooter
          loading={formik.isSubmitting}
          confirmAction={formik.submitForm}
          closeAction={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default observer(AdminChangePasswordModal);
