import { FC } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

export type Option = {
  id: number;
  name: string;
};

type DropdownInputProps = SelectProps & {
  value: number | string | null;
  formik: any;
  label: string;
  dataField: string;
  options: Array<Option>;
  helperText?: any;
};

const Dropdown: FC<DropdownInputProps> = ({
  value,
  formik,
  label,
  dataField,
  options,
  helperText,
}) => {
  return (
    <FormControl
      fullWidth
      error={Boolean(formik.touched[dataField] && formik.errors[dataField])}
      size="small"
    >
      <InputLabel id={`${label}_${dataField}`}>{label}</InputLabel>
      <Select
        labelId="status-channel_modal"
        id="status-channel_modal"
        value={value}
        label={label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name={dataField}
      >
        {options.map((option: Option, index: number) => {
          return (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
      {Boolean(formik.touched[dataField] && formik.errors[dataField]) &&
        helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;
