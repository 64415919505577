import { createBrowserHistory } from "history";
import { ReCAPTCHA_SITE_KEY_TEST } from "./constants";
import dayjs from "dayjs";
import { isEqual } from "lodash";

export function setDisabledLinkStatus(href: string, match: any) {
  let { url } = match;
  return href === url ? { disabled: true } : null;
}

export function getRandomValue(max = 999999, min = 111111) {
  return Math.round(Math.random() * (max - min) + min);
}

export function cutUrl(url: string) {
  return url.slice(-1) === "/" ? url.slice(0, -1) : url;
}

export const redirect = (url = "") => {
  let history = createBrowserHistory();
  history.push(url);
};

export function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name: string, value: string, options: any = {}) {
  options = {
    path: "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (!options.expires) {
    options.expires = new Date(
      new Date().setDate(new Date().getDate() + 7)
    ).toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
  setCookie(name, "", { "max-age": -1 });
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (typeof a[orderBy] === "string" || typeof b[orderBy] === "string") {
    return String(b[orderBy]).localeCompare(String(a[orderBy]), undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// vanilla sort
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function positionRender(channelName: string, position: number) {
  const posiotionPad = String(position).padStart(2, "0");
  return `${channelName}:${posiotionPad}`;
}

export function statusRender(status: number) {
  const STATUS_MAP = {
    0: "Not ready",
    1: "Ready",
    2: "In rent",
  };

  // @ts-ignore
  return STATUS_MAP[status];
}

export function getTouchedData(
  initialValues: object,
  touchedData: object
): object {
  let touched: any = {};
  Object.keys(touchedData).forEach((key) => {
    // @ts-ignore
    if (touchedData[key] !== initialValues[key]) {
      // @ts-ignore
      touched[key] = touchedData[key];
    }
  });
  return touched;
}

export function copyToClipboard(text: string) {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text);
  } else {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

export function actionByEnterPress(event: KeyboardEvent, action: any) {
  if (event.keyCode === 13) {
    event.preventDefault();
    action(event);
  }
}

export function Deferred() {
  // @ts-ignore
  let self = this;
  // @ts-ignore
  this.promise = new Promise((resolve, reject) => {
    self.resolve = resolve;
    self.reject = reject;
  });
}

export function fetchWithTimeout(resource: string, options: any = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  return fetch(resource, {
    ...options,
    signal: controller.signal,
  }).then((response) => {
    clearTimeout(id);
    return response;
  });
}

export const getServerMeta = (url: string) => {
  const websocketBaseUrl = `${window.location.protocol.replace(
    "http",
    "ws"
  )}//api.${window.location.hostname}:5080/api/v1/websocket`;

  let defaultWSUrl =
    window.location.hostname === "localhost"
      ? "ws://api.dev.community.goantifraud.com:5080/api/v1/websocket"
      : websocketBaseUrl;

  let formattedUrl = `${window.location.protocol}//${window.location.hostname}/${url}`;

  let defaultMeta = {
    "ws-url": defaultWSUrl,
    "captcha-site-key": ReCAPTCHA_SITE_KEY_TEST,
  };

  // @ts-ignore
  let deferred = new Deferred();
  fetchWithTimeout(formattedUrl, { method: "GET" })
    .then((response) => {
      if (response.status !== 200) {
        console.log("Error " + response.status + " - " + response.statusText);
        deferred.resolve(defaultMeta);
      } else {
        response.json().then((serverMeta) => {
          deferred.resolve({ ...defaultMeta, ...serverMeta });
        });
      }
    })
    .catch(() => deferred.resolve(defaultMeta));

  return deferred.promise;
};

export function dateCellRender(date: Date | string) {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDuration(seconds: number) {
  const days = Math.floor(seconds / 86400);
  const time = {
    d: days > 0 ? days : null,
    h: Math.floor(seconds / 3600) % 24,
    m: Math.floor(seconds / 60) % 60,
    s: seconds % 60,
  };

  let formattedTime = "";
  if (time.d) {
    formattedTime += `${time.d} ${time.d === 1 ? "day" : "days"} `;
  }
  formattedTime += `${String(time.h).padStart(2, "0")}:${String(
    time.m
  ).padStart(2, "0")}:${String(time.s).padStart(2, "0")}`;

  return formattedTime;
}

export const checkChannelsInCart = (data: Array<any>, cart: Array<any>) => {
  let channelsIDs = data.map((i: any) => i.id).sort();
  let cartIDs = cart.map((i: any) => i.id).sort();

  return isEqual(channelsIDs, cartIDs);
};
