import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { FC, useEffect, useState } from "react";
import { Option } from "./Dropdown";
import dayjs from "dayjs";

const INTERVALS = [
  { id: 1, name: "day" },
  { id: 2, name: "week" },
  { id: 3, name: "month" },
];

type MULTIPLIER_TYPE = {
  [key: number]: number;
};

const MULTIPLIER: MULTIPLIER_TYPE = {
  1: 1,
  2: 7,
  3: 30,
};

type IntervalProps = TextFieldProps & {
  formik: any;
  label: string;
  dataField: string;
  onChange: (value: number) => void;
  max?: number;
};

const IntervalField: FC<IntervalProps> = ({
  formik,
  label,
  dataField,
  onChange,
  max,
  value,
  ...props
}: IntervalProps) => {
  const [timeValue, setValue] = useState<number>(0);
  const [periodValue, setPeriod] = useState<number>(INTERVALS[0].id);

  const getValue = (value: number, period: number) => {
    // if (period === 3) {
    //   let dateNow = dayjs();
    //   let newDate = dateNow.add(value, "month");
    //   return newDate.diff(dateNow, "day");
    // }
    return value * MULTIPLIER[period];
  };

  const handlePeriodChange = (e: any) => {
    const pValue = Number(e.target.value);
    setPeriod(e.target.value);
    onChange(getValue(timeValue, pValue));
  };

  const handleTimeChange = (e: any) => {
    let tValue = e.target.value;

    if (!tValue.match(/^\d+$/) && tValue !== "") {
      return;
    }

    if (tValue < 0 || tValue > (max ?? Infinity)) {
      return;
    }

    if (tValue.match(/^0+\d+$/)) {
      tValue = tValue.slice(1);
    }
    setValue(tValue);
    onChange(getValue(tValue, periodValue));
  };

  useEffect(() => {
    // @ts-ignore
    let period: number =
      Object.keys(MULTIPLIER)
        .slice()
        .reverse()
        // @ts-ignore
        .find((key: string) => value % MULTIPLIER[key] === 0) || 1;

    // @ts-ignore
    setValue(value / MULTIPLIER[period]);
    setPeriod(value === 0 ? INTERVALS[0].id : period);
  }, []);

  const isError = Boolean(
    formik.touched[dataField] && formik.errors[dataField]
  );

  return (
    <FormControl sx={{ height: 70, width: "100%" }}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        height={100}
      >
        <Grid item sx={{ width: "30%" }}>
          <TextField
            label={label}
            size="small"
            fullWidth
            value={timeValue}
            name={dataField}
            onChange={handleTimeChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Box sx={{ marginLeft: "20px" }} />
        <Grid item sx={{ width: "65%" }}>
          <FormControl fullWidth error={false} size="small">
            <InputLabel id="period">Period</InputLabel>
            <Select
              labelId="period"
              id="period"
              value={periodValue}
              label="Period"
              onChange={handlePeriodChange}
              onBlur={formik.handleBlur}
              name="period"
              // inputProps={{ 'aria-label': 'Without label' }}
            >
              {INTERVALS.map((option: Option, index: number) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {isError && (
        <FormHelperText error={isError}>
          {formik.errors[dataField]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default IntervalField;
