import { isEqual } from "lodash";
import { makeAutoObservable } from "mobx";

type Statistic = {
  create_time: string;
  channel_name: string;
  active_time: number;
  rent_time: number;
  price: number;
};

type DateType = {
  start_date: string | null;
  end_date: string | null;
};

export default class StatisticStore {
  statistic: Map<string, Statistic[]> = new Map();
  date: DateType = {
    start_date: null,
    end_date: null,
  };
  touchedData: DateType = {
    start_date: null,
    end_date: null,
  };
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setStatistic = (data: any, type: string) => {
    if (!data) return;
    this.touchedData = {
      ...this.date,
    };
    this.statistic.set(type, data);
  };

  setStartDate = (date: any) => {
    this.date = {
      ...this.date,
      start_date: date,
    };
  };

  setEndDate = (date: any) => {
    this.date = {
      ...this.date,
      end_date: date,
    };
  };

  get touched() {
    return !isEqual(this.touchedData, this.date);
  }

  setLoadingState = (state: boolean = true) => {
    this.loading = state;
  };

  clearDate = () => {
    this.date = {
      start_date: null,
      end_date: null,
    };
    this.touchedData = {
      start_date: null,
      end_date: null,
    };
  };
}
