import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC } from "react";

type NumericInputProps = TextFieldProps & {
  formik: any;
  label: string;
  dataField: string;
  min?: number;
  max?: number;
  precision?: number;
};

const NumericInput: FC<NumericInputProps> = ({
  formik,
  label,
  dataField,
  precision = 0,
  ...props
}) => {
  const isError = Boolean(
    formik.touched[dataField] && formik.errors[dataField]
  );

  const handleChange = (e: any) => {
    let value = e.target.value;
    const regExpr =
      precision > 0
        ? new RegExp(`^-*[0-9]*(\\.[0-9]{0,${precision}})?$`)
        : new RegExp(`^[0-9]*$`);

    if (!value.match(regExpr) && value !== "") {
      return;
    }

    if (value < (props.min ?? -Infinity) || value > (props.max ?? Infinity)) {
      return;
    }

    if (precision && value.match(/\./)) {
      const [int, dec] = value.split(".");
      if (dec.length > precision!) {
        return;
      }
    }

    if (value.match(/^0+\d+$/)) {
      value = value.slice(1);
    }

    formik.setFieldValue(dataField, value !== null ? String(value) : ""); //formik doesn't want to pass correct number..
  };

  return (
    <TextField
      style={{
        minHeight: 70,
        margin: 0,
      }}
      size="small"
      error={isError}
      fullWidth
      helperText={(isError && formik.errors[dataField]) || props.helperText}
      label={label}
      margin="normal"
      name={dataField}
      onBlur={formik.handleBlur}
      onChange={handleChange}
      value={formik.values[dataField]}
      variant="outlined"
      type="number"
      {...props}
    />
  );
};

export default NumericInput;
