import { Box } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";

interface IProps {
  loading: boolean;
  confirmAction: () => void;
  closeAction: () => void;
  disabled?: boolean;
}

export default function ModalFooter({
  loading,
  confirmAction,
  closeAction,
  disabled = false,
}: IProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-evenly",
        marginTop: "auto",
      }}
    >
      <Box>
        <LoadingButton
          sx={{
            py: 2,
            // alignSelf: 'flex-end',
            margin: "auto",
            width: 140,
          }}
          disabled={disabled}
          loading={loading}
          color="success"
          fullWidth
          size="small"
          variant="contained"
          onClick={confirmAction}
        >
          Accept
        </LoadingButton>
      </Box>
      <Box>
        <Button
          sx={{
            py: 2,
            // alignSelf: 'flex-end',
            margin: "auto",
            width: 140,
          }}
          color="error"
          fullWidth
          size="small"
          variant="outlined"
          onClick={closeAction}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
