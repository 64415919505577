import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import GroupIcon from "@mui/icons-material/Group";
import RouterIcon from "@mui/icons-material/Router";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { observer } from "mobx-react";

import { useAuth } from "../utils/Auth";
import { useWS } from "../utils/websocket";
import React, { useContext, useEffect, useState } from "react";
import { deleteCookie, getCookie, setCookie } from "../utils/etc";
import {
  ADMIN,
  ADMIN_DEVICES,
  ADMIN_USERS,
  MARKET,
  NOTIFICATIONS,
  STATISTICS,
} from "../routes/constants";
import { ADM_KEY, ADM_VALUE } from "../utils/constants";
import { StoreContext } from "../App";

const AccountMenu = observer(() => {
  const { send, logOut } = useWS();
  const { cart } = useContext(StoreContext);

  const { user, isAuth, setUser, setAuth } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const getUser = async () => {
      let [userInfo] = await send("users", "user_info", {});
      setUser(userInfo);
      let adm = getCookie(ADM_KEY) === ADM_VALUE;
      if (userInfo?.role === "admin" && !adm) {
        setCookie(ADM_KEY, ADM_VALUE);
      }
    };

    getUser();
  }, [cart.stateChanged]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const signOut = async (event: React.MouseEvent<HTMLElement>) => {
    await logOut();
    deleteCookie("access_token");
    deleteCookie("cart");
    deleteCookie(ADM_KEY);
    setAuth(false);
    setUser(null);
    navigate(`/login`);
  };

  const navigateUsers = (event: React.MouseEvent<HTMLElement>) => {
    navigate(`/${ADMIN}/${ADMIN_USERS}`);
  };
  const navigateDevices = (event: React.MouseEvent<HTMLElement>) => {
    navigate(`/${ADMIN}/${ADMIN_DEVICES}`);
  };
  const navigateStatistic = (event: React.MouseEvent<HTMLElement>) => {
    navigate(`/${STATISTICS}/real`);
  };

  const navigateNotifications = (event: React.MouseEvent<HTMLElement>) => {
    navigate(`/${NOTIFICATIONS}`);
  };

  const adminItems = () => {
    return user?.role === "admin"
      ? [
          <Divider key={"admin_div"}>Admin</Divider>,
          <MenuItem key={"admin_users"} onClick={navigateUsers}>
            <ListItemIcon>
              <GroupIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Users
          </MenuItem>,
          <MenuItem key={"admin_devices"} onClick={navigateDevices}>
            <ListItemIcon>
              <RouterIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Devices
          </MenuItem>,
        ]
      : [];
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 40, height: 40 }}>
              {user?.email[0].toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: "160px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>Balance: {`$${user?.balance}`}</MenuItem>
        <MenuItem onClick={navigateNotifications}>
          <ListItemIcon>
            <NotificationsNoneIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Notifications
        </MenuItem>
        <MenuItem onClick={navigateStatistic}>
          <ListItemIcon>
            <AnalyticsIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Statistic
        </MenuItem>
        {adminItems()}
        <Divider />
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" color="action" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
});

export default AccountMenu;
