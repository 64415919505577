import * as React from "react";
import { FC } from "react";
import { LinearProgress, LinearProgressProps } from "@mui/material";
import Box from "@mui/material/Box";

type LoaderProps = LinearProgressProps & {
  loading: boolean;
};

const Loader: FC<LoaderProps> = ({ loading, ...props }) => {
  if (!loading) return null;

  return (
    <Box sx={{ ...props.sx }}>
      <LinearProgress color="secondary" />
    </Box>
  );
};

export default Loader;
