import React, { FC, ReactNode, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

interface IProps {
  children: ReactNode;
}

const HeaderMenu: FC<IProps> = ({ children }) => {
  const matches = useMediaQuery("(min-width:917px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hiddenMenu = () => {
    return (
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            height: 64,
            width: 64,
            borderRadius: 0,
            color: "primary.main",
          }}
        >
          {!open ? (
            <MenuIcon sx={{ fontSize: 30 }} />
          ) : (
            <MenuOpenIcon sx={{ fontSize: 30 }} />
          )}
        </IconButton>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "menu",
          }}
        >
          <MenuList>
            {React.Children.map<ReactNode, ReactNode>(children, (child) => (
              <MenuItem
                selected={
                  location.pathname ===
                  // @ts-ignore
                  (child?.props.to || child?.props.children.props.to)
                }
                onClick={() => {
                  // @ts-ignore
                  if ("props" in child) {
                    navigate(child?.props.to || child?.props.children.props.to);
                  }
                  handleClose();
                }}
              >
                {/*@ts-ignore*/}
                {child?.props.label || child?.props.children.props.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
    );
  };

  return matches ? <>{children}</> : hiddenMenu();
};

export default HeaderMenu;
