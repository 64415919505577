import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Divider } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import DeviceLogsList from "../Tables/DeviceLogsList";
import { useWS } from "../../utils/websocket";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // bgcolor: 'background.paper',
  boxShadow: 24,
};

interface IProps {
  open: boolean;
  handleClose: () => void;
  selected: number | null;
}

const DeviceLogsModal = ({ open, handleClose, selected }: IProps) => {
  const { send } = useWS();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    if (selected) {
      setLoading(true);
      const [res] = await send("virtual_channels", "log", {
        id: selected,
      });
      if (res) {
        setData(res);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      disableEscapeKeyDown
    >
      <Box sx={{ ...style }}>
        <Card>
          <CardHeader
            action={
              <IconButton
                aria-label="close"
                onClick={handleClose}
                color={"primary"}
              >
                <CloseRounded />
              </IconButton>
            }
            title={"LOGS"}
            sx={{
              backgroundColor: "secondary.light",
              color: "secondary.contrastText",
            }}
          />
          <Divider />
          <CardContent
            sx={{
              "&.MuiCardContent-root": {
                padding: 0,
                "&MuiCardContent-root:last-child": {
                  paddingBottom: "32px",
                },
              },
            }}
          >
            <Box
              style={{
                height: 700,
                width: 640,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DeviceLogsList data={data} loading={loading} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default DeviceLogsModal;
