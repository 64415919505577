import CssBaseline from "@mui/material/CssBaseline";
import { Fragment } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { getServerMeta } from "./utils/etc";

getServerMeta("meta").then((res: any) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <Fragment>
      <CssBaseline />
      <App wsURL={res["ws-url"]} captchaSiteKey={res["captcha-site-key"]} />
    </Fragment>
  );
});
