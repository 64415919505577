import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect } from "react";

import { Link, useMatch, useNavigate } from "react-router-dom";

function TTabs(props: any) {
  const navigate = useNavigate();
  const routeMatchGates = useMatch(`${props.path}/gates`);
  const routeMatchSimBanks = useMatch(`${props.path}/simbanks`);

  const tabValue = (routeMatchGates || routeMatchSimBanks)?.pathname;

  useEffect(() => {
    if (!tabValue) {
      navigate(`${props.path}/gates`, { replace: true });
    }
  });

  return (
    <Tabs value={tabValue || `${props.path}/gates`}>
      <Tab
        label="Gateways"
        value={`${props.path}/gates`}
        to={`${props.path}/gates`}
        component={Link}
      />
      {/*<Tab label="SIM Banks" value={`${props.path}/simbanks`} to={`${props.path}/simbanks`} component={Link} />*/}
    </Tabs>
  );
}

export default function TabsRouter(props: any) {
  return (
    <Box sx={{ width: "100%", paddingLeft: 1 }}>
      <TTabs {...props} />
    </Box>
  );
}
