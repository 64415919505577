import { Box, Divider, Grid } from "@mui/material";
import { isNull, omitBy } from "lodash";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import TableCard from "../components/primitives/TableCard";
import Table from "../containers/Tables/NotificationsTable";
import { useWS } from "../utils/websocket";

const Notifications = (props: any) => {
  const { notifications, loading, setNotifications, setLoadingState } =
    useContext(StoreContext).notifications;

  const { send } = useWS();

  const getNotifications = useCallback(async () => {
    setLoadingState(true);
    let [notificationsData] = await send("notifications", "history", {
      limit: 999999,
      offset: 0,
    });

    setLoadingState(false);
    setNotifications(notificationsData);
  }, []);

  const removeNotification = useCallback(async (id: number) => {
    await send("notifications", "delete", {
      id,
    });
    await getNotifications();
  }, []);

  useEffect(() => {
    async function fetch() {
      await getNotifications();
    }

    fetch();
  }, []);

  return (
    <Main disableNav>
      <Box
        component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <TableCard>
              <Table
                data={notifications}
                loading={loading}
                remove={removeNotification}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default observer(Notifications);
