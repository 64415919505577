import Add from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import SignalWifiBadIcon from "@mui/icons-material/SignalWifiBad";
import { Grid, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "../../components/primitives/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useCallback, useRef } from "react";
import ContextMenu from "../../components/ContextMenu";
import { getComparator, Order, stableSort } from "../../utils/etc";
import ConfirmModal from "../Modals/ConfirmModal";
import DeviceModal from "../Modals/DeviceModal";
import Loader from "../../components/Loader";
import { TABLE_PAGINATION } from "../../utils/constants";
import RemoveGateModal from "../Modals/RemoveGateModal";

type Data = {
  id: number;
  name: string;
  region_name: string;
  rented: boolean;
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    width: 210,
  },
  {
    id: "region_name",
    numeric: false,
    disablePadding: false,
    label: "Region",
    width: 160,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ minWidth: headCell.width, maxWidth: headCell.width }}
            key={headCell.id}
            align="center"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: Data[];
  selectData: (arrayOfIds: number[]) => void;
  selected: number[];
  refresh: any;
  remove: any;
  terminateRent: any;
  loading: boolean;
}

export default function DeviceList({
  data = [],
  selectData,
  selected,
  refresh,
  remove,
  terminateRent,
  loading,
}: IProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PAGINATION[0]);

  const [deviceModalState, handleDeviceModalChange] = React.useState(false);
  const [modalType, handleModalType] = React.useState<"create" | "edit">(
    "create"
  );

  const [confirmTerminateModal, handleConfirmTerminateModalChange] =
    React.useState<boolean>(false);

  const [confirmRemoveModal, handleConfirmRemoveModalChange] =
    React.useState<boolean>(false);

  const openRemoveModal = () => {
    handleConfirmRemoveModalChange(true);
  };

  const closeRemoveModal = () => {
    handleConfirmRemoveModalChange(false);
  };

  const openConfirmTerminateModal = () => {
    handleConfirmTerminateModalChange(true);
  };

  const closeConfirmTerminateModal = () => {
    handleConfirmTerminateModalChange(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      selectData(newSelecteds);
      return;
    }
    selectData([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    selectData([id]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const confirmRemove = async () => {
    let [res] = await remove(selected[0]);
    if (res) {
      refresh();
    }
    return res;
  };

  const confirmTerminate = async () => {
    let [res] = await terminateRent(selected[0]);
    if (res) {
      refresh();
    }
    return res;
  };

  const openDeviceModal = useCallback(() => {
    handleDeviceModalChange(true);
  }, []);

  const openModalOnCreate = useCallback(() => {
    openDeviceModal();
    handleModalType("create");
  }, []);

  const openModalOnEdit = useCallback(() => {
    openDeviceModal();
    handleModalType("edit");
  }, []);

  const closeDeviceModal = useCallback(() => {
    handleDeviceModalChange(false);
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }} elevation={6}>
        <TableContainer
          sx={{
            minWidth: "50%",
            maxHeight: "calc(100vh - 272px)",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: "50%" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody sx={{ position: "relative" }}>
              <Loader
                sx={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                }}
                loading={loading}
              />
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ContextMenu
                      key={row.id}
                      items={[
                        {
                          name: "Edit",
                          method: openModalOnEdit,
                          icon: SettingsIcon,
                        },
                        {
                          name: "Terminate Rent",
                          method: openConfirmTerminateModal,
                          icon: SignalWifiBadIcon,
                          useDivider: true,
                          disabled: row.rented === false,
                        },
                        {
                          name: "Delete",
                          method: openRemoveModal,
                          icon: DeleteIcon,
                        },
                      ]}
                      callback={(event) => handleClick(event, row.id)}
                    >
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell
                          sx={{
                            maxWidth: 210,
                            minWidth: 210,
                          }}
                          id={labelId}
                          align="left"
                          padding="normal"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 160,
                            minWidth: 160,
                          }}
                          align="left"
                          padding="normal"
                        >
                          {row.region_name}
                        </TableCell>
                      </TableRow>
                    </ContextMenu>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          sx={{
            flex: 1,
            margin: "auto",
            alignItems: "center",
            justifyContent: "space-arround",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{
              position: "relative",
            }}
          >
            <IconButton
              size="large"
              color="success"
              aria-label="add device"
              onClick={openModalOnCreate}
              sx={{
                position: "absolute",
                top: -13,
                left: 16,
                p: 0,
              }}
            >
              <Add fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              marginLeft: "auto",
            }}
          >
            <TablePagination
              sx={{
                ".MuiButtonBase-root": {
                  color: "primary.main",
                },
                ".Mui-disabled": {
                  color: "primary.light",
                },
              }}
              rowsPerPageOptions={TABLE_PAGINATION}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
      {confirmTerminateModal ? (
        <ConfirmModal
          open={confirmTerminateModal}
          handleClose={closeConfirmTerminateModal}
          title="Terminate Rent"
          text="Confirm that you want to terminate rent"
          confirm={confirmTerminate}
        />
      ) : null}
      {confirmRemoveModal ? (
        <RemoveGateModal
          open={confirmRemoveModal}
          handleClose={closeRemoveModal}
          confirm={confirmRemove}
          terminateRent={confirmTerminate}
          inRent={data.find((item) => item.id === selected[0])?.rented || false}
        />
      ) : null}
      {deviceModalState ? (
        <DeviceModal
          open={deviceModalState}
          handleClose={closeDeviceModal}
          refresh={refresh}
          type={modalType}
        />
      ) : null}
    </Box>
  );
}
