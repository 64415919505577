import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { Badge } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import { StoreContext } from "../App";
import BuyChannelsModal from "../containers/Modals/BuyChannelsModal";
import { useLocation } from "react-router-dom";
import { useWS } from "../utils/websocket";
import { isEqual } from "lodash";
import { checkChannelsInCart } from "../utils/etc";

const Cart = observer(() => {
  const { cart } = useContext(StoreContext);
  const [open, handleBuyModalChange] = React.useState(false);
  const location = useLocation();

  const { send } = useWS();

  const openModal = () => {
    handleBuyModalChange(true);
  };

  const closeModal = () => {
    handleBuyModalChange(false);
  };

  const validateChannels = async () => {
    if (!cart.cart.length) return;

    const [res] = await send("finance", "total", {
      channels: cart.cart.map((i) => i.id),
      rent_interval: 1,
    });

    if (res && !checkChannelsInCart(res.channels, cart.cart)) {
      cart.updateCartItems(res.channels);
      cart.changeState();
    }
  };

  useEffect(() => {
    validateChannels();
  }, []);

  useEffect(() => {
    if (location.pathname === "/market/gates" && cart.modalSavedData) {
      openModal();
    }
  }, [location.pathname, cart.modalSavedData]);

  return (
    <React.Fragment>
      <Badge badgeContent={cart.cart.length} color="primary" sx={{ mx: 2 }}>
        <IconButton color="secondary" aria-label="Cart" onClick={openModal}>
          <ShoppingCart />
        </IconButton>
      </Badge>
      {open ? <BuyChannelsModal open={open} handleClose={closeModal} /> : null}
    </React.Fragment>
  );
});

export default Cart;
