import { makeAutoObservable } from "mobx";

export default class OptionsStore {
  options: Record<string, any[]> = {};

  constructor() {
    makeAutoObservable(this);
  }

  setOption = (key: string, data: any[]) => {
    if (!data) return;

    this.options[key] = data;
  };

  getOption = (key: string) => {
    return this.options[key] || [];
  };
}
