import { Box, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect } from "react";
import { StoreContext } from "../App";
import Main from "../components/Main";
import TableCard from "../components/primitives/TableCard";
import List from "../containers/Tables/RentedDevicesList";
import Table from "../containers/Tables/RentedDevicesTable";
import { useWS } from "../utils/websocket";

const RentedDevices = (props: any) => {
  const { virtualDevices, cart } = useContext(StoreContext);

  const {
    gates,
    setSelectedDevices,
    selected,
    selectedChannels,
    selectedChannelName,
    setGates,
    setChannels,
    channelsStateLoading,
    gatesStateLoading,
    setChanelsLoading,
    setGatesLoading,
  } = virtualDevices;

  const { deleteAPI, getAPI } = useWS();

  const getChannels = useCallback(async () => {
    setChanelsLoading(true);

    let [virtual_channels] = await getAPI("virtual_channels");
    setChannels(virtual_channels);

    setChanelsLoading(false);
  }, []);

  const getGates = useCallback(async () => {
    setGatesLoading(true);

    let [virtual_gates] = await getAPI("virtual_gates");
    setGates(virtual_gates);

    setGatesLoading(false);
  }, []);

  const refresh = useCallback(async () => {
    getChannels();
    getGates();
  }, []);

  const removeDevice = useCallback(async (id: number) => {
    return await deleteAPI(
      "virtual_gates",
      {
        id,
      },
      "Device successfully removed"
    );
  }, []);

  useEffect(() => {
    async function fetch() {
      return await refresh();
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.stateChanged]);

  return (
    <Main path="/rent">
      <Box
        component="main"
        sx={{
          py: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ flexGrow: 1, width: 420 }}>
            <TableCard>
              <List
                loading={gatesStateLoading}
                refresh={refresh}
                remove={removeDevice}
                data={gates}
                selectData={setSelectedDevices}
                selected={selected}
              />
            </TableCard>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item sx={{ flexGrow: 7 }}>
            <TableCard>
              <Table
                loading={channelsStateLoading}
                channelName={selectedChannelName}
                data={selectedChannels}
              />
            </TableCard>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};

export default observer(RentedDevices);
