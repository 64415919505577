import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { Dayjs } from "dayjs";
import * as React from "react";
import DatePicker from "../../components/primitives/DatePicker";

export type Filter = {
  start_date: Dayjs | null;
  end_date: Dayjs | null;
};

interface IProps {
  setStartDate: any;
  setEndDate: any;
  onApply: any;
  touched: boolean;
  date: any;
}

export default function StatiscticFilters({
  setStartDate,
  setEndDate,
  onApply,
  touched,
  date,
}: IProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mx: 2, mt: 2 }}>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <DatePicker
            label={"Start Date"}
            onChange={setStartDate}
            value={date.start_date}
          />
        </FormGroup>
      </FormControl>
      <Box p={2} />
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <DatePicker
            label={"End Date"}
            onChange={setEndDate}
            value={date.end_date}
          />
        </FormGroup>
      </FormControl>
      <Box p={2} />
      <Box
        sx={{ display: "flex", my: 1, justifyContent: "end", paddingRight: 1 }}
      >
        {touched && (
          <Box>
            <Button
              sx={{
                width: 100,
                height: 32,
              }}
              color="secondary"
              fullWidth
              size="small"
              variant="outlined"
              onClick={onApply}
              disabled={!touched}
            >
              Apply
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
