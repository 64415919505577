import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/material";
import ToolTip from "./ToolTip";

interface IProps {
  linked: boolean;
}

export default function GateLink({ linked }: IProps) {
  if (linked) return null;

  return (
    <ToolTip
      title={
        <Box>
          <div>You need to link your gate.</div>
          <div>
            Use{" "}
            <Box
              component="span"
              sx={{ color: "#3FC79A", textDecoration: "underline" }}
            >
              Link Gate
            </Box>{" "}
            in context menu
          </div>
        </Box>
      }
    >
      <Box
        sx={{
          // width: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InfoIcon color="warning" sx={{ fontSize: 14 }} />
      </Box>
    </ToolTip>
  );
}
